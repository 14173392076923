import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import "./DataTablePubblicazioni.css";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import InfoButton from "../InfoButton/InfoButton";
import DettaglioPubblicazione from "../DettaglioPubblicazione/DettaglioPubblicazione";
import { Alert, AlertTitle, GlobalStyles, LinearProgress, Stack, SvgIcon } from "@mui/material";
import { ReactComponent as ExcelSvg } from "../../assets/excel-file.svg";

function ExcelIcon() {
  return (
    <SvgIcon>
      <ExcelSvg fill="green"></ExcelSvg>
    </SvgIcon>
  );
}
function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
        csvOptions={{
          fileName: "elencoPubblicazioni",
        }}
      />
      <GridToolbarQuickFilter/>
    </GridToolbarContainer>
  );
}

// component per tabella Pubblicazioni
export default function DataTablePubblicazioni(props) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [cellValues, setCellValue] = React.useState();

  const handleClickOpen = (cellValues) => {
    setOpen(true);
    setCellValue(cellValues);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, loading, error } = useQuery(props.query, {
    variables: props.formValue,
  });

  if (loading) return <LinearProgress />;
  if (error) return (
    <Alert severity="error">
      <AlertTitle>Error!</AlertTitle>
      {error.message}
    </Alert>
  );

  const rows = data[Object.keys(data)[0]];

  const columns = [
    {
      field: "idPubblicazione",
      headerName: t("CODICE"),
      flex: 1,
    },
    {
      field: "anno",
      headerName: t("SIMPLE_YEAR"),
      flex: 1,
    },
    {
      field: "lineaDiRicerca",
      headerName: t("LINEA_RICERCA"),
      flex: 1,
    },
    {
      field: "titolo",
      headerName: t("TITOLO"),
      flex: 1,
    },
    {
      field: "rivista",
      headerName: t("RIVISTA"),
      flex: 1,
    },
    {
      field: "autori",
      headerName: t("AUTORI"),
      flex: 1,
    },
    {
      field: "Dettaglio",
      headerName: t("SELEZIONA"),
      flex: 0.5,
      minWidth: 85,
      disableExport: true,
      renderCell: (cellValues) => {
        return (
          <InfoButton
            handleClickOpen={() => {
              handleClickOpen(cellValues);
            }}
          ></InfoButton>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <GlobalStyles
            styles={{
              ".MuiDataGrid-menuList": {
                backgroundColor: "#ebffe6",
              },
            }}
          />
          <DataGrid
            getRowId={(row) => row[props.uniqueField]}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            disableColumnSelector
            disableColumnMenu
            pageSize={20}
            rowsPerPageOptions={[20]}
            autoHeight
            sx={{
              "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
                color: "green",
              },
            }}
            getRowHeight={() => 140}
            localeText={{
              toolbarExport: t("DOWNLOAD_EXCEL"),
              toolbarExportCSV: t("DOWNLOAD_CSV"),
              toolbarQuickFilterPlaceholder: t("CERCAPUBBLICAZIONI"),
            }}
            components={{
              Toolbar: MyExportButton,
              ExportIcon: ExcelIcon,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {t("NO_RESULT")}
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              // Hide column autori, the other columns will remain visible
              autori: false,
            }}
          />
          {cellValues && (
            <DettaglioPubblicazione
              open={open}
              handleClose={handleClose}
              cellValues={cellValues}
            ></DettaglioPubblicazione>
          )}
        </div>
      </div>
    </div>
  );
}
