import React from "react";
import { Box, Typography } from "@mui/material";
import "../IRIS.css";
import IRISTeamIMG from '../../../assets/team_iris.png';

export default function IRISTeam() {
  return (
    <div>
        
        <Box bgcolor="#EA5B3A" textAlign="center">
            <Typography variant="body1" pt={2} pb={2} color="white">
                SALUTI DAL TEAM IRIS!
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={IRISTeamIMG}
                width="100%"
            />
        </Box>

        <br/>
        
        <Box>
            <Typography variant="body2" textAlign="center" className="textGrey"  style={{fontSize:"small"}}  >
                <i>Copyright © IRIS 2020, Ministero della Salute. All rights reserved.</i>
                <br/><br/>
                <b>Our mailing address is:</b><br/>
                iris-helpdesk@apre.it
                <br/><br/>
                <b>Ricevi questa email nell'ambito dell'informativa del Ministero della Salute</b>
                <br/>
                "Workflow Ricerca 2.0 - Sistema Informativo della Ricerca Biomedica"
                <br/>
                <b>e dell'iniziativa finanziata dal Ministero della Salute:</b>
                Servizio di “Supporto agli IRCCS per l'internazionalizzazione della ricerca - Anni 2020 e 2021"
                <br/>
                (CIG n. 8220564005)
            </Typography>
        </Box>

        <hr/>

    </div>
  );
}
