import React from 'react';
import {Route, Routes} from 'react-router-dom';

import * as routes from './Routes';
import Home from "../components/Home/Home";
import AreaProgetti from "../components/AreaProgetti/AreaProgetti";
import AreaPubblicazioni from "../components/AreaPubblicazioni/AreaPubblicazioni";
import Questionario from "../components/Questionario/Questionario";
import IRISHome from '../components/IRIS/Update/IRISHome';
import IRISServizi from '../components/IRIS/IRISServizi';
import IRISArchivio from '../components/IRIS/IRISArchivio';
import IRISComunicazioni from '../components/IRIS/IRISComunicazioni';
import BussoleIRCSS from '../components/BussoleIRCSS/BussoleIRCSS';
import BussoleIRCSSArchivio from '../components/BussoleIRCSS/BussoleIRCCSArchivio';

export const AppRoutes = () =>
    <Routes>
        <Route path={routes.HOME} element={<Home/>}/>
        <Route path={routes.AREAPROGETTI} element={<AreaProgetti/>}/>
        <Route path={routes.AREAPUBBLICAZIONI} element={<AreaPubblicazioni/>}/>
        <Route path={routes.GRADIMENTO} element={<Questionario/>}/>
        <Route path={routes.IRISHOME} element={<IRISHome/>}/>
        <Route path={routes.IRISSERVIZI} element={<IRISServizi/>}/>
        <Route path={routes.IRISARCHIVIO} element={<IRISArchivio/>}/>
        <Route path={routes.IRISCOMUNICAZIONI} element={<IRISComunicazioni/>}/>
        <Route path={routes.BUSSOLEIRCSS} element={<BussoleIRCSS/>}/>
        <Route path={routes.BUSSOLEIRCSSARCHIVIO} element={<BussoleIRCSSArchivio/>}/>

    </Routes>;

export default AppRoutes;
