import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";
import { GET_UO } from "../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

export default function UO(props) {
  const { t } = useTranslation();

  // get Unità Operative Coinvolte for ProgettiRf dato codice progetto ( tramite props )
  const { data, loading, error } = useQuery(GET_UO, {
    variables: {
      codiceProgetto: props.codiceProgetto,
    },
  });

  if (loading) return "Loading...";
  if (error) return (
    <Alert severity="error">
      <AlertTitle>Error!</AlertTitle>
      {error.message}
    </Alert>
  );

  if (data.getUO !== undefined && data.getUO.length !== 0) {
    return (
      <Box color="black">
        <Typography variant="h6" component="span" gutterBottom>
          {t("UO_COINVOLTE")}: <br />
        </Typography>
        {data.getUO.map((UO) => (
          <Typography variant="subtitle1" gutterBottom component="span" key={UO.istituto}>
            {UO.istituto}
            <br />
          </Typography>
        ))}
      </Box>
    );
  } else {
    return null;
  }
}
