import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import FactSheetsIMG from '../../../assets/factsheetes_2022.png';

export default function IRISFactsSheets() {

    function openLinkIRISFactsheetEDCTP(){
        const urlReport = "https://mcusercontent.com/523af143bc18413a060734433/files/48a5f732-c872-9c6e-5026-85a40ca0fda9/IRIS_FACTSHEET_EDCTP3_new.pdf"
        window.open(urlReport, '_blank', 'noopener,noreferrer');
    }

    function openLinkIRISFactsheetIHI(){
        const urlReport = "https://mcusercontent.com/523af143bc18413a060734433/files/8f8cec6f-a867-7668-00e8-615cc8307beb/IRIS_FACTSHEET_IHI.pdf"
        window.open(urlReport, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>

        <Box>
            <Typography variant="body2" textAlign="justify">
                <strong>
                IRIS FACTSHEETS 2022
                </strong><br/>
                Schede informative su iniziative chiave per la Ricerca collaborativa in ambito Salute
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={FactSheetsIMG}
                width="100%"
            />
        </Box>
        
        <br/>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                Con gli <b className="textHighlight" >IRIS Factsheet</b> , il team di APRE ha raccolto e sistematizzato per gli IRCCS 
                le informazioni utili alla partecipazione nell'ambito delle EU Partnership istituzionalizzate:  
                <ul key="infoEU">
                    <li key="infoEU1">
                        European & Developing Countries Clinical Trials Partnership (EDCTP3)
                    </li>
                    <li key="infoEU2">
                        Innovative Health Initiative (IHI)
                    </li>
                </ul>
            </Typography>
        </Box>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                Horizon Europe continua a sostenere partenariati europei (European Partnerships) tra la Commissione, da un lato, 
                e i paesi membri, il settore privato, le fondazioni e altre parti interessate, dall’altro. 
                L’obiettivo è quello di affrontare le sfide globali e la modernizzazione industriale attraverso sforzi concertati di ricerca 
                e innovazione. 
                <b>
                    Mettendo insieme partner privati e pubblici, i partenariati europei aiutano ad evitare la duplicazione degli investimenti 
                    e dovrebbero contribuire a ridurre la frammentazione delle attività di ricerca e innovazione nell’Unione.
                </b>
            </Typography>
        </Box>

        <br/>

        <Box style={{backgroundColor:"#7CA27B",color:"#F2F2F2",padding:"1em"}}>
            <Typography style={{fontSize:"small"}}  gutterBottom textAlign="justify">
                <div style={{textAlign:"center",marginLeft:"auto",marginRight:"auto",textDecoration:"bold"}}>
                    <strong>Tipologie di Partenariati Europei</strong>
                </div>
                Horizon Europe stabilisce le condizioni e i principi per la creazione di tre nuove tipologie di partenariati:
                <ul key="he">
                    <li key="he1">
                        <b>Partenariati co-programmati</b> | Si tratta di partenariati tra la Commissione e partner privati e/o pubblici. 
                        Si basano su protocolli d’intesa e/o accordi contrattuali.  
                    </li>
                    <li key="he2">
                        <b>Partenariati cofinanziati</b> | Si tratta di partenariati tra la Commissione e partner principalmente pubblici, 
                        che pongono al centro del consorzio le agenzie finanziatrici della ricerca a livello nazionale e altre autorità 
                        degli Stati membri. Si basano su un programma di R&I concordato dai partner e sono attivati da un’azione di 
                        co-finanziamento di Horizon Europe.   
                    </li>
                    <li key="he3">
                        <b>Partenariati istituzionalizzati</b> | Si tratta di partenariati tra l’Unione eropea e soggetti pubblici e/o privati, 
                        caratterizzati da una prospettiva a lungo termine e da un elevato livello di integrazione, e implementati da strutture 
                        create ad hoc. Sono istituiti sulla base dell’articolo 185 del Tfue – partenariati di tipo pubblico-pubblico tra l’Ue 
                        e gli Stati membri-, dell’articolo 187 – partenariati di tipo pubblico-privato tra l’Ue e il settore industriale 
                        (Joint Undertakings), o del regolamento dell’Istituto europeo di innovazione e tecnologia (EIT) – comunità della conoscenza 
                        e dell’innovazione (KIC).  
                    </li>
                </ul>
            </Typography>
        </Box>

        <br/>
        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center"  role="button" onClick={()=>openLinkIRISFactsheetEDCTP()}>
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Scarica IRIS Factsheet | EDCTP3
                </Typography>
            </Button>
        </Box>
        <br/>

        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center"  role="button" onClick={()=>openLinkIRISFactsheetIHI()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Scarica IRIS Factsheet | IHI
                </Typography>
            </Button>
        </Box>
        <br/>
        <hr/>
    </div>
  );
}
