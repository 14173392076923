import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import IRISWebinarIMG from '../../../assets/archivio.jpg';

export default function IRISWebinar() {

    function openLinkArchivio(){
        const urlArchivio = "https://apre-my.sharepoint.com/:f:/g/personal/debonis_apre_it/Eqzrz3H9Ie1MoZ9HCtyWuxoBGBoN52sQ47f1-oUEMn0xCA?e=JGexEd"
        window.open(urlArchivio, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>
        
        <Box>
            <Typography variant="body2" textAlign="justify">
                <br/>
                <strong>
                    WEBINAR IRIS
                </strong><br/>
                Ora disponibile l'archivio dei materiali formativi
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={IRISWebinarIMG}
                width="100%"
            />
        </Box>

        <br/>

        <Box>
            <Typography variant="body2" textAlign="justify"  style={{fontSize:"small"}} >
                Un'azione di capacity building mirata, basata sulle esigenze informative, formative e di assistenza emerse dai gruppi 
                di lavoro dei Grant Office presso gli IRCCS nelle precedenti edizioni di IRIS e nell’ambito del Focus Group IRIS dedicato 
                (Focus Group <i>Formazione</i>), si è sviluppata tra il 2021 e il 2022 coinvolgendo sia i Grant Office che i Ricercatori 
                degli IRCCS in <b className="textHighlight" >13 appuntamenti formativi targati IRIS</b>.
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography variant="body2" textAlign="justify"  style={{fontSize:"small"}} >
                I partecipanti hanno potuto consolidare le proprie competenze in materia di <b>progettazione</b> in Horizon, approfondendo 
                anche aree specifiche del Programma in cui trova spazio la ricerca sanitaria (<b>ERC, MSCA</b>) e affrontando aspetti 
                trasversali legati alla preparazione di una proposta di successo (<b>Impatto, Open Science, Proprietà Intellettuale, Etica e 
                GDPR, Gender Equality Plan</b>). <br/>
                Momenti di lezione frontale si sono alternati a momenti di verifica e interazione, oltre che all’esercitazione pratica, 
                come nel caso dei corsi sugli <b>aspetti legali e finanziari</b>, dedicati anche alla costruzione del budget di una proposta Horizon. 
            </Typography>
        </Box>

        <br/>

        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center"  role="button" onClick={()=>openLinkArchivio()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Scarica l'archivio dei materiali formativi
                </Typography>
            </Button>
        </Box>
        <br/>
        <hr/>
    </div>
  );
}
