export const HOME = '/';
export const AREAPROGETTI = '/areaprogetti';
export const AREAPUBBLICAZIONI = '/areapubblicazioni';
export const GRADIMENTO = '/gradimento';
export const IRISHOME = '/iris';
export const IRISSERVIZI = '/iris/servizi';
export const IRISARCHIVIO = '/iris/archivio';
export const IRISCOMUNICAZIONI = '/iris/comunicazioni';
export const BUSSOLEIRCSS = '/bussole-ircss';
export const BUSSOLEIRCSSARCHIVIO = '/bussole-ircss/archivio';

