import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Button,
  Collapse,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./DettaglioProgetto5x1000.css";
import DettaglioQuotaTable from "../DettaglioQuotaTable/DettaglioQuotaTable";

// component generico per dettaglio progetto 5x1000
export default function DettaglioProgetto5x1000(props) {
  const { t } = useTranslation();

  const [show, setShow] = React.useState(false);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  const [getDettaglioQuota, QUOTA] = useLazyQuery(props.secondQuery, {
    variables: {
      idProgetto: props.cellValues.row.idProgetto,
    },
  });

  const handleClick = () => {
    getDettaglioQuota();
    setShow((prev) => !prev);
  };

  const { data, loading, error } = useQuery(props.query, {
    variables: {
      codiceProgetto: props.cellValues.id,
      idProgetto: props.cellValues.row.idProgetto,
    },
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error!</AlertTitle>
        {error.message}
      </Alert>
    );

  const rows = data[Object.keys(data)[0]];

  //funzione usata per fare un check sui campi del dettaglio e per formattare le date e le quote
  const checkField = (key, value) => {
    if (key.startsWith("data")) {
        return new Intl.DateTimeFormat("en-GB").format(new Date(value));
    } else if (
      key === "quotaRendicontata" ||
      key === "quotaErogata" ||
      key === "quotaProgetto" 
    ) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    } else {
      return value;
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("DETTAGLI_PROGETTO")} {props.cellValues.id}:
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={2}>
              {rows.length !== 0
                ? Object.keys(rows).map(
                    (key, i) =>
                      key !== "__typename" && (
                        <Box key={i} color="black">
                          <Typography
                            variant="h6"
                            component="span"
                            gutterBottom
                          >
                            {t(key.toUpperCase())}: <br />
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="span"
                          >
                            {rows[key] !== null
                              ? checkField(key, rows[key])
                              : t("NON_DISPONIBILE")}
                          </Typography>
                        </Box>
                      )
                  )
                : null}
              <Box width={"100%"}>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  style={{ width: "100%" }}
                >
                  {show
                    ? "NASCONDI DETTAGLIO QUOTA 5 PER MILLE PROGETTO"
                    : "MOSTRA DETTAGLIO QUOTA 5 PER MILLE PROGETTO"}
                </Button>
                {QUOTA.data !== undefined && (
                  <Collapse in={show}>
                    <DettaglioQuotaTable
                      rows={QUOTA.data.getProgetti5x1000DettaglioQuote}
                    ></DettaglioQuotaTable>
                  </Collapse>
                )}
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
