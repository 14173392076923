import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "./DataTable.css";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import InfoButton from "../InfoButton/InfoButton";
import DettaglioProgetto from "../DettaglioProgetto/DettaglioProgetto";
import DettaglioProgetto5x1000 from "../DettaglioProgetto5x1000/DettaglioProgetto5x1000";
import { Alert, AlertTitle, GlobalStyles, LinearProgress, Stack, SvgIcon } from "@mui/material";
import { ReactComponent as ExcelSvg } from "../../assets/excel-file.svg";

function ExcelIcon() {
  return (
    <SvgIcon>
      <ExcelSvg fill="green"></ExcelSvg>
    </SvgIcon>
  );
}
function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
        csvOptions={{
          fileName: "elencoProgetti",
        }}
      />
    </GridToolbarContainer>
  );
}

// component per tabella geenerica ( dinamica per tutti i progetti)
export default function DataTable(props) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [cellValues, setCellValue] = React.useState();

  const handleClickOpen = (cellValues) => {
    setOpen(true);
    setCellValue(cellValues);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, loading, error } = useQuery(props.query, {
    variables: props.formValue,
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error!</AlertTitle>
        {error.message}
      </Alert>
    );

  const rows = data[Object.keys(data)[0]];

  const columns = [];

  if (rows.length !== 0) {
    for (var i = 0; i < Object.keys(rows[0]).length; i++) {
      if (
        Object.keys(rows[0])[i] !== "__typename" &&
        Object.keys(rows[0])[i] !== "nomeResponsabile" &&
        Object.keys(rows[0])[i] !== "cognomeResponsabile"
      ) {
        columns.push({
          field: Object.keys(rows[0])[i],
          headerName: t(Object.keys(rows[0])[i].toUpperCase()),
          flex:
            Object.keys(rows[0])[i] === "titolo"
              ? 3
              : Object.keys(rows[0])[i] === "anno"
              ? 0.5
              : 1,
          minWidth:
            Object.keys(rows[0])[i] === "titolo"
              ? 510
              : Object.keys(rows[0])[i] === "anno"
              ? 85
              : 170,
        });
      } else if (Object.keys(rows[0])[i] === "nomeResponsabile") {
        columns.push({
          field: "Responsabile",
          headerName: t("RESPONSABILE"),
          flex: 1,
          minWidth: 170,
          valueGetter: (params) =>
            `${params.row.nomeResponsabile || ""} ${
              params.row.cognomeResponsabile || ""
            }`,
        });
      }
    }
    columns.push({
      field: "Dettaglio",
      headerName: t("SELEZIONA"),
      flex: 0.5,
      minWidth: 85,
      disableExport: true,
      renderCell: (cellValues) => {
        return (
          <InfoButton
            handleClickOpen={() => {
              handleClickOpen(cellValues);
            }}
          ></InfoButton>
        );
      },
    });
  }

  /* <IconButton
            aria-label="Informazioni"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            <InfoIcon color="primary"/>
          </IconButton>*/

  //return <div></div>;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <GlobalStyles
            styles={{
              ".MuiDataGrid-menuList": {
                backgroundColor: "#ebffe6",
              },
            }}
          />
          <DataGrid
            getRowId={(row) => row[props.uniqueField]}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            disableColumnSelector
            disableColumnMenu
            pageSize={20}
            rowsPerPageOptions={[20]}
            autoHeight
            sx={{
              "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
                color: "green",
              },
            }}
            getRowHeight={() => "auto"}
            localeText={{
              toolbarExport: t("DOWNLOAD_EXCEL"),
              toolbarExportCSV: t("DOWNLOAD_CSV"),
            }}
            components={{
              Toolbar: MyExportButton,
              ExportIcon: ExcelIcon,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {t("NO_RESULT")}
                </Stack>
              ),
            }}
          />
          {cellValues && !props.detailButtonFlag && (
            <DettaglioProgetto
              open={open}
              handleClose={handleClose}
              cellValues={cellValues}
              query={props.detailQuery}
            ></DettaglioProgetto>
          )}
          {cellValues && props.detailButtonFlag && (
            <DettaglioProgetto5x1000
              open={open}
              handleClose={handleClose}
              cellValues={cellValues}
              query={props.detailQuery}
              secondQuery={props.detailSecondQuery}
            ></DettaglioProgetto5x1000>
          )}
        </div>
      </div>
    </div>
  );
}
