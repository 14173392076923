import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
  Box,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import {
  GET_ISTITUTI,
  GET_LINEE_DI_RICERCA,
  GET_PROGETTI_RC,
  GET_PROGETTO_RC_BY_ID,
} from "../../GraphQL/Queries";
import { useLazyQuery } from "@apollo/client";
import MouseOverPopover from "../MouseOverPopover/MouseOverPopover";
import DataTable from "../DataTable/DataTable";

let inputForm = {};

export default function FormRc() {
  const { t } = useTranslation();

  const min_year = 1910;
  const max_year = new Date().getFullYear();

  const initialState = {
    idIstituto: "",
    anno: new Date().getFullYear(),
    titolo: "",
    // nomeResponsabile: "",
    // cognomeResponsabile: "",
    codiceProgettoWF: "",
    paroleChiave: "",
    idLineaDiRicerca: "",
  };

  const [formValue, setformValues] = React.useState(initialState);

  const [submit, setSubmit] = React.useState(false);

  const handleChange = (evnt) => {
    const inputFieldValue = evnt.target.value;
    const inputFieldName = evnt.target.name;
    const NewInputValue = { ...formValue, [inputFieldName]: inputFieldValue };
    setformValues(NewInputValue);
    setSubmit(false);
  };

  const handleSubmit = (event) => {
    inputForm = {
      ...formValue,
      anno: formValue.anno !== "" ? parseInt(formValue.anno, 10) : null,
      idIstituto:
        formValue.idIstituto !== "" ? parseInt(formValue.idIstituto, 10) : null,
      codiceProgettoWF:
        formValue.codiceProgettoWF !== ""
          ? parseInt(formValue.codiceProgettoWF, 10)
          : null,
      idLineaDiRicerca:
        formValue.idLineaDiRicerca !== ""
          ? parseInt(formValue.idLineaDiRicerca, 10)
          : null,
    };
    setSubmit(true);
    event.preventDefault();
  };

  const handleReset = () => {
    setformValues(initialState);
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
  });

  // controllo parametri form, se esiste almeno un campo non vuoto OK , se tutti i campi vuoti ERRORE
  const checkParameters = (formValue) => {
    let show = true;
    for (const value of Object.values(formValue)) {
      if (value.length !== 0) {
        show = false;
      }
    }
    return show;
  };

  // check if an istitute and year is chosen to activate the select
  const checkLineaDiRicerca = (idIstituto, anno) => {
    let disable = true;
    if (idIstituto.length !== 0 && anno.length !== 0) disable = false;

    return disable;
  };

  const ISTITUTI = useQuery(GET_ISTITUTI);

  const [getLinee, LINEE] = useLazyQuery(GET_LINEE_DI_RICERCA, {
    variables: {
      anno: parseInt(formValue.anno),
      codice_istituto: parseInt(formValue.idIstituto),
    },
  });

  if (ISTITUTI.loading)
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  if (ISTITUTI.error) return (
    <Alert severity="error">
      <AlertTitle>Error!</AlertTitle>
      {ISTITUTI.error.message}
    </Alert>
  );

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-standard-label">
                {t("INSTITUTE")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={t("INSTITUTE")}
                value={formValue.idIstituto}
                name="idIstituto"
                onChange={handleChange}
              >
                {ISTITUTI.data.getIstituti.map((ISTITUTI) => (
                  <MenuItem key={ISTITUTI.id} value={ISTITUTI.id}>
                    {ISTITUTI.nomeBreve}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label={t("SIMPLE_YEAR")}
              variant="standard"
              type="number"
              inputProps={{ min: min_year, max: max_year }}
              value={formValue.anno}
              onChange={handleChange}
              fullWidth
              name="anno"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label={t("TITOLO")}
              variant="standard"
              fullWidth
              value={formValue.titolo}
              onChange={handleChange}
              name="titolo"
            />
          </Grid>
          {/* <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("NOME_RESPONSABILE")}
              variant="standard"
              fullWidth
              value={formValue.nomeResponsabile}
              onChange={handleChange}
              name="nomeResponsabile"
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("COGNOME_RESPONSABILE")}
              variant="standard"
              fullWidth
              value={formValue.cognomeResponsabile}
              onChange={handleChange}
              name="cognomeResponsabile"
            />
          </Grid> */}
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("CODICE_PROGETTO_WF")}
              variant="standard"
              fullWidth
              value={formValue.codiceProgettoWF}
              name="codiceProgettoWF"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("CODICE_PROGETTO_WF")}
              description={t("CODICE_PROGETTO_WF_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("PAROLE_CHIAVE")}
              variant="standard"
              fullWidth
              name="paroleChiave"
              value={formValue.paroleChiave}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("PAROLE_CHIAVE")}
              description={t("PAROLE_CHIAVE_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={1.8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-standard-label">
                {t("LINEA_RICERCA")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={t("LINEA_RICERCA")}
                name="idLineaDiRicerca"
                value={formValue.idLineaDiRicerca}
                onChange={handleChange}
                disabled={checkLineaDiRicerca(
                  formValue.idIstituto,
                  formValue.anno
                )}
                onOpen={() => getLinee()}
              >
                {LINEE.data !== undefined
                  ? LINEE.data.getLineediricerca.map((linea) => (
                      <MenuItem key={linea.id} value={linea.id}>
                        {linea.titolo}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("LINEA_RICERCA")}
              description={t("LINEA_RICERCA_DESC")}
            ></MouseOverPopover>
          </Grid>
          {checkParameters(formValue) && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>{t("API_KEY_ALERT")}</AlertTitle>
                {t("PARAMETRI_RICERCA")}
              </Alert>
            </Grid>
          )}
          <Grid item xs={9}></Grid>
          <Grid item xs={1.5}>
            <Button
              type="submit"
              variant="contained"
              p={2}
              disabled={checkParameters(formValue)}
            >
              {t("CERCA")}
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="neutral"
                p={2}
                onClick={handleReset}
              >
                {t("PULISCI")}
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </form>
      <Box mt={3}>
        {submit && (
          //pass form values, graphql query, and the name of the field returned by the backend used as ID
          <DataTable
            formValue={inputForm}
            query={GET_PROGETTI_RC}
            detailQuery={GET_PROGETTO_RC_BY_ID}
            uniqueField="idProgetto"
            detailButtonFlag={false}
          ></DataTable>
        )}
      </Box>
    </Box>
  );
}
