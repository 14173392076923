import React from "react";
import "./Navbar.module.css";
import {
  Grid,
  Stack,
  Tabs,
  Tab,
  Box,
  IconButton,
  Icon,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import logo from "../../assets/logo_ministero.png";
import flag from "../../assets/linea_tricolore.png";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useLocation } from "react-router-dom";
import i18n from "i18next";
import itSvg from "../../assets/italy.svg";
import ukSvg from "../../assets/united-kingdom.svg";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const location = useLocation();
  const { t } = useTranslation();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
    },
  });

  return (
    <Box bgcolor="#06c">
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        pl={4}
        pr={4}
        pt={3}
        pb={1}
      >
        <Grid item xs={6}>
          <Box>
            <Stack spacing={1}>
              <Box>
                <img
                  src={logo}
                  alt="Italian Ministry of Health Logo"
                  width="55%"
                />
              </Box>
              <Box color="white">
                <b>{t("SUBTITLE")}</b>
              </Box>
              <Box>
                <img src={flag} alt="Linea Tricolore" width="55%" />
              </Box>
              <Box color="white">
                <Typography variant="h6">
                  <b>{t("AREA_PUBBLICA")}</b> Workflow Ricerca 2.0
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Stack direction="row" spacing={2}>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f2f2f2",
                  },
                }}
                onClick={() => {
                  i18n.changeLanguage("it");
                }}
              >
                <Icon>
                  <img src={itSvg} alt="it flag svg"></img>
                </Icon>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f2f2f2",
                  },
                }}
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              >
                <Icon>
                  <img src={ukSvg} alt="uk flag svg"></img>
                </Icon>
              </IconButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <ThemeProvider theme={theme}>
        <Box
          bgcolor="#06c"
          color="white"
          borderBottom={1}
          borderColor="divider"
          pt={1}
          pl={3}
          pr={3}
        >
          <Tabs
            value={"/" + location.pathname.split("/")[1]}
            indicatorColor="primary"
            textColor="inherit"
            aria-label="tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              icon={<HomeIcon />}
              aria-label="home"
              component={Link}
              to={"/"}
              value={"/"}
            />
            <Tab
              label={t("PROJECTS")}
              component={Link}
              to={"/areaprogetti"}
              value={"/areaprogetti"}
            />
            <Tab
              label={t("PUBLICATIONS")}
              component={Link}
              to={"/areapubblicazioni"}
              value={"/areapubblicazioni"}
            />
            <Tab label="IRIS" component={Link} to={"/iris"} value={"/iris"} />
            <Tab
              label="BUSSOLE IRCCS"
              component={Link}
              to={"/bussole-ircss"}
              value={"/bussole-ircss"}
            />
            <Tab
              label="QUESTIONARIO GRADIMENTO"
              component={Link}
              to={"/gradimento"}
              value={"/gradimento"}
            />
          </Tabs>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
