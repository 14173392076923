import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, FormHelperText } from "@mui/material";

export default function RadioButtonsGroup(props) {

  // component utilizzato più volte come gruppo di bottoni nel questionario gradimento
  return (
    <Box>
      <FormControl error={props.error}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          {props.formLabel}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={props.name}
          value={props.value}
          onChange={props.handleChange}
        >
          <FormControlLabel value={1} control={<Radio />} label="1" />
          <FormControlLabel value={2} control={<Radio />} label="2" />
          <FormControlLabel value={3} control={<Radio />} label="3" />
          <FormControlLabel value={4} control={<Radio />} label="4" />
          <FormControlLabel value={5} control={<Radio />} label="5" />
          <FormControlLabel value={6} control={<Radio />} label="6" />
        </RadioGroup>
        <FormHelperText>Selezionare una risposta</FormHelperText>
      </FormControl>
    </Box>
  );
}
