import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import AlertNewsIMG from '../../../assets/alert_news.png';
import OpportunitaIRCCSIMG from '../../../assets/opportunita_irccs.png';

export default function IRISAlertsNews() {

    function openLinkArchivio(){
        const urlArchivio = "https://apre-my.sharepoint.com/:x:/g/personal/debonis_apre_it/EQ2f-sTT0bdNq6aiyaPn2XkBvmFUoWNtSGUoM1MR_iqSyg?e=oIIPhT"
        window.open(urlArchivio, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>
        
        <Box>
            <Typography variant="body2" textAlign="justify">
                <br/>
                <strong>
                    IRIS ALERT & NEWS
                </strong><br/>
                Strumenti di aggiornamento su bandi e altre opportunità per gli IRCCS 
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={AlertNewsIMG}
                width="100%"
            />
        </Box>

        <br/>

        <Box>
            <Typography variant="body2" textAlign="justify" className="textGrey"  style={{fontSize:"small"}}  >
                Attraverso la creazione di un processo informativo strutturato e continuativo (<b className="textHighlight" >IRIS News, 
                Magazine e Partner Search</b> ) il team di IRIS ha svolto una consistente azione di <b>sensibilizzazione del sistema IRCCS 
                verso le diverse opportunità esistenti sia nel contesto che al di fuori del WP (Work Programme)</b> dedicato alla ricerca 
                sanitaria nell’ambito dei Programmi Quadro Europei per la Ricerca e l’Innovazione (es. altri Cluster, IHI, ERC, Marie Sklowdoska Curie, etc).
                <br/>
                Inoltre, è stato messo a punto un sistema di aggiornamento - <b className="textHighlight" >IRIS Alert</b> - basato su un accurato 
                monitoraggio di iniziative/bandi europei in grado di consentire la selezione di quelli di interesse per la ricerca sanitaria. 
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography variant="body2" textAlign="justify" className="textGrey"  style={{fontSize:"small"}}  >
                Sono <b>28 le linee di finanziamento</b> sulla base delle quali è stato svolto il <b>monitoraggio periodico</b> delle 
                iniziative e dei bandi per la ricerca e l’innovazione in campo sanitario di livello europeo ed internazionale:
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={OpportunitaIRCCSIMG}
                width="100%"
                style={{border:"1px solid black"}}
            />
        </Box>

        <br/>

        <Box>
            <Typography variant="body2" textAlign="justify" className="textGrey"  style={{fontSize:"small"}}  >
                L'archivio online (IRIS Alert), riservato ai Grant Office degli IRCCS, è reso facilmente consultabile scaricando il relativo 
                file excel, per una più agevole diffusione all'interno del proprio Istituto. 
            </Typography>
        </Box>

        <br/>

        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center" role="button" onClick={()=>openLinkArchivio()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Accedi all'archivio dei bandi
                </Typography>
            </Button>
        </Box>
        <br/>
        <hr/>
    </div>
  );
}
