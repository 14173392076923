import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createTheme,
  Tab,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./AreaProgetti.module.css";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FormRf from "../FormRf/FormRf";
import FormRc from "../FormRc/FormRc";
import FormRcReti from "../FormRcReti/FormRcReti";
import FormCc from "../FormCc/FormCc";
import Form5x1000 from "../Form5x1000/Form5x1000";

// component che svolge la funzione di contenitore dell'area progetti
export default function AreaProgetti() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#06c",
      },
    },
  });

  return (
    <Box pt={5} pb={5} pl={2} pr={2} bgcolor="#eeeeee">
      <Card>
        <CardHeader
          title={<strong>{t("PROJECTS")}</strong>}
          subheader={t("DESCR_PROJECTS")}
        />
        <ThemeProvider theme={theme}>
          <CardContent>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="API tabs"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={t("RICERCA_FINALIZZATA")} value="1" />
                  <Tab label={t("RICERCA_CORRENTE")} value="2" />
                  <Tab label={t("RICERCA_CORRENTE_RETI")} value="3" />
                  <Tab label={t("CONTO_CAPITALE")} value="4" />
                  <Tab label={t("5_X_MILLE")} value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <FormRf></FormRf>
              </TabPanel>
              <TabPanel value="2">
                <FormRc></FormRc>
              </TabPanel>
              <TabPanel value="3">
                <FormRcReti></FormRcReti>
              </TabPanel>
              <TabPanel value="4">
                <FormCc></FormCc>
              </TabPanel>
              <TabPanel value="5">
                <Form5x1000></Form5x1000>
              </TabPanel>
            </TabContext>
          </CardContent>
        </ThemeProvider>
      </Card>
    </Box>
  );
}
