import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Stack, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./DettaglioPubblicazione.css";

// component per dettaglio Pubblicazione
export default function DettaglioPubblicazione(props) {
  const { t } = useTranslation();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  const rows = props.cellValues.row;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box display="flex" alignItems="center" m={2}>
            <Box flexGrow={1}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("DETTAGLI_PUBBLICAZIONE")}:
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={props.handleClose}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#bf0202",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box m={3}>
            <Stack spacing={2}>
              {rows.length !== 0
                ? Object.keys(rows).map(
                    (key, i) =>
                      key !== "__typename" && (
                        <Box key={i} color="black">
                          <Typography
                            variant="h6"
                            component="span"
                            gutterBottom
                          >
                            {t(key.toUpperCase())}: <br />
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="span"
                          >
                            {rows[key] !== null
                              ? rows[key]
                              : t("NON_DISPONIBILE")}
                          </Typography>
                        </Box>
                      )
                  )
                : null}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
