import React, { useLayoutEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./App.css";
import AppRoutes from "../../navigation/AppRoutes";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationsEn from "../../traduzioni/lang_en_US.json";
import translationsIt from "../../traduzioni/lang_it_IT.json";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://areapubblica.api.cbim.it/graphql",
  cache: new InMemoryCache(),
});

i18n.use(initReactI18next).init({
  resources: {
    it: { translation: translationsIt },
    en: { translation: translationsEn },
  },
  lng: "it",
});

// utilizzato per riposizionarsi ad inizio pagina ogni qualvolta si navighi tra le pagine 
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Router>
          <Wrapper>
            <Navbar />
            <AppRoutes />
            <Footer />
          </Wrapper>
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
