import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RadioButtonsGroup from "../RadioButtonsGroup/RadioButtonsGroup";
import "./Questionario.css";
import ReCAPTCHA from "react-google-recaptcha";
import { POST_SATISFACTION_FORM } from "../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";

// Questionario gradimento dove dopo la verifica dei campi e del reCAPTCHA viene caricata a DB una valutazione dell'utente
export default function Questionario() {
  const initialValues = {
    ques1Rc: "",
    ques1Rf: "",
    ques1Pub: "",
    ques1Obs: "",
    ques2Rc: "",
    ques2Rf: "",
    ques2Pub: "",
    ques2Obs: "",
    ques3: "",
    ques3Bis: "",
    comments: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  // reCAPTCHA
  const [isVerified, setIsVerified] = useState(false);

  const [isSubmit, setIsSubmit] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleChange = (evnt) => {
    const inputFieldValue = evnt.target.value;
    const inputFieldName = evnt.target.name;
    const NewInputValue = { ...formValues, [inputFieldName]: inputFieldValue };
    setFormValues(NewInputValue);
  };

  // reCAPTCHA
  const onChange = (evnt) => {
    setIsVerified(true);
  };

  // validazione campi
  const validate = (values) => {
    const errors = {};
    if (!values.ques1Rc) {
      errors.ques1Rc = true;
    }
    if (!values.ques1Rf) {
      errors.ques1Rf = true;
    }
    if (!values.ques1Pub) {
      errors.ques1Pub = true;
    }
    if (!values.ques2Rc) {
      errors.ques2Rc = true;
    }
    if (!values.ques2Rf) {
      errors.ques2Rf = true;
    }
    if (!values.ques2Pub) {
      errors.ques2Pub = true;
    }
    if (!values.ques3) {
      errors.ques3 = true;
    }
    if (!values.ques3Bis) {
      errors.ques3Bis = true;
    }
    return errors;
  };

  // mutation che viene chiamata in seguito per caricare valutazione dell'utente se soddisfatti tutti i controlli
  const [postForm, { data, loading, error }] = useMutation(
    POST_SATISFACTION_FORM,
    {
      variables: formValues,
    }
  );

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && isVerified) {
      // chiamata funzione per effettuare mutation
      postForm();
    }
  }, [formErrors, isSubmit, postForm, isVerified]);

  return (
    <Box pt={5} pb={5} pl={2} pr={2} className="layout">
      <Card>
        <CardHeader
          sx={{ margin: 2 }}
          title={<strong>Questionario di soddisfazione dell'utente</strong>}
          subheader={
            <span>
              <br></br>Gentile Utente, <br></br>
              nell’ambito del nostro Sistema di Gestione per la Qualità e in
              accordo alla norma UNI EN ISO 9001:2015, desideriamo sottoporLe un
              questionario che costituisce un ausilio molto importante al fine
              di ottenere delle informazioni sul grado di soddisfazione e
              contemporaneamente ci permetterà di individuare gli aspetti che
              necessitano specifiche azioni di miglioramento. Le saremmo
              pertanto grati se volesse compilare in ogni sua parte il
              questionario, che prevede anche la possibilità di inviarci
              suggerimenti, consigli o commenti che possono aiutarci a
              migliorare, così da essere sempre più capaci di rispondere alle
              esigenze degli utenti. La ringraziamo per la collaborazione e per
              gli spunti di miglioramento che vorrà suggerirci.
              <br></br>
              N.B. I dati raccolti nel presente questionario sono raccolti in
              forma anonima.
            </span>
          }
        />
        <CardContent sx={{ margin: 2 }}>
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Box>
                  <Typography variant="h6" gutterBottom component="div" mb={2}>
                    1) Esprima un giudizio sulla{" "}
                    <i>
                      <u>facilità</u>
                    </i>{" "}
                    e la{" "}
                    <i>
                      <u>velocità di accesso</u>{" "}
                    </i>
                    alle informazioni riguardanti i servizi erogati dal portale
                    Area Pubblica Workflow della ricerca 2.0
                    <br></br>
                    <i>
                      (1 = per nulla soddisfatto | 6 = completamente
                      soddisfatto)
                    </i>
                  </Typography>
                  <Stack spacing={2}>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Progetti di ricerca corrente:"
                        name="ques1Rc"
                        handleChange={handleChange}
                        value={formValues.ques1Rc}
                        error={formErrors.ques1Rc}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Progetti di ricerca finalizzata:"
                        name="ques1Rf"
                        handleChange={handleChange}
                        value={formValues.ques1Rf}
                        error={formErrors.ques1Rf}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Pubblicazioni:"
                        name="ques1Pub"
                        handleChange={handleChange}
                        value={formValues.ques1Pub}
                        error={formErrors.ques1Pub}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <TextField
                        label="Inserisca qui eventuali osservazioni sul giudizio espresso:"
                        inputProps={{
                          maxLength: 200,
                        }}
                        value={formValues.ques1Obs}
                        helperText={`${formValues.ques1Obs.length}/200`}
                        onChange={handleChange}
                        margin="normal"
                        variant="standard"
                        name="ques1Obs"
                        fullWidth
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom component="div" mb={2}>
                    2) Esprima un giudizio sulla{" "}
                    <i>
                      <u>completezza</u>
                    </i>{" "}
                    delle informazioni reperite:
                    <br></br>
                    <i>
                      (1 = per nulla soddisfatto | 6 = completamente
                      soddisfatto)
                    </i>
                  </Typography>
                  <Stack spacing={2}>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Progetti di ricerca corrente:"
                        name="ques2Rc"
                        handleChange={handleChange}
                        value={formValues.ques2Rc}
                        error={formErrors.ques2Rc}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Progetti di ricerca finalizzata:"
                        name="ques2Rf"
                        handleChange={handleChange}
                        value={formValues.ques2Rf}
                        error={formErrors.ques2Rf}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <RadioButtonsGroup
                        formLabel="Pubblicazioni:"
                        name="ques2Pub"
                        handleChange={handleChange}
                        value={formValues.ques2Pub}
                        error={formErrors.ques2Pub}
                      ></RadioButtonsGroup>
                      <Divider />
                    </Box>
                    <Box>
                      <TextField
                        label="Inserisca qui eventuali osservazioni sul giudizio espresso:"
                        inputProps={{
                          maxLength: 200,
                        }}
                        value={formValues.ques2Obs}
                        helperText={`${formValues.ques2Obs.length}/200`}
                        onChange={handleChange}
                        margin="normal"
                        variant="standard"
                        name="ques2Obs"
                        fullWidth
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom component="div" mb={2}>
                    3) Esprima il Suo{" "}
                    <i>
                      <u>livello globale di soddisfazione</u>{" "}
                    </i>
                    nei confronti del portale Area Pubblica Workflow della
                    ricerca 2.0:
                    <br></br>
                    <i>
                      (1 = per nulla soddisfatto | 6 = completamente
                      soddisfatto)
                    </i>
                  </Typography>
                  <Box>
                    <RadioButtonsGroup
                      name="ques3"
                      handleChange={handleChange}
                      value={formValues.ques3}
                      error={formErrors.ques3}
                    ></RadioButtonsGroup>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom component="div" mb={2}>
                    Tale livello di soddisfazione è in linea con le Sue
                    aspettative iniziali?
                  </Typography>
                  <Box>
                    <FormControl error={formErrors.ques3Bis}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="ques3Bis"
                        value={formValues.ques3Bis}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Sì, è in linea"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="No, è maggiore"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label="No, è minore"
                        />
                      </RadioGroup>
                      <FormHelperText>Selezionare una risposta</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    <TextField
                      label="Se desidera esprimere qualsiasi altro commento
                        aggiuntivo La invitiamo a farlo con la massima libertà, terremo conto delle Sue
                        osservazioni:"
                      inputProps={{
                        maxLength: 200,
                      }}
                      value={formValues.comments}
                      helperText={`${formValues.comments.length}/200`}
                      onChange={handleChange}
                      margin="normal"
                      variant="standard"
                      name="comments"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom component="div" mb={2}>
                    La ringraziamo per la cortese collaborazione
                  </Typography>
                  <ReCAPTCHA
                    sitekey="6LcGg4IUAAAAACHoIl9h-XMg4VsvYV40OKEIVm75"
                    onChange={onChange}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    type="submit"
                    variant="contained"
                    disabled={(Object.keys(formErrors).length === 0 && isSubmit) || !isVerified}
                  >
                    SUBMIT FORM
                  </Button>
                </Box>
              </Stack>
            </form>
            {loading && <LinearProgress sx={{ mt: 2 }} />}
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Error!</AlertTitle>
                {error.message}
              </Alert>
            )}
            {data && (
              <Alert variant="filled" severity="success" sx={{ mt: 2 }}>
                <AlertTitle>Questionario compilato</AlertTitle>
                Grazie di aver compilato il questionario
              </Alert>
            )}
            {Object.keys(formErrors).length !== 0 && isSubmit && (
              <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                Questionario incompleto. Compilare le domande mancanti.
              </Alert>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}


