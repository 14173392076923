import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./DettaglioProgetto.css";
import UO from "../UO/UO";

// component generico per dettaglio progetto ( dinamico per ProgettiRF, ProgettiRC, ProgettiCC, ProgettiRCR )
export default function DettaglioProgetto(props) {
  const { t } = useTranslation();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  //query che dato l'id progetto ne recupera il dettaglio (da props viene passata dinamicamente la query a seconda del progetto)
  const { data, loading, error } = useQuery(props.query, {
    variables: { id: props.cellValues.id },
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error!</AlertTitle>
        {error.message}
      </Alert>
    );

  //risultato query
  const rows = data[Object.keys(data)[0]];


  //funzione usata per fare un check sui campi del dettaglio e per formattare le date e le quote
  const checkField = (key, value, projectType) => {
    if (key.startsWith("data")) {
      //per i soli ProgettiRf il loro formato data dd-mm-yyyy non è valido, quindi converto in yyyy-mm-dd (questo perchè per i ProgettiRf le date a DB sono salvate come stringhe)
      if(projectType === "ProgettiRf"){
        let newDate = value.split("-").reverse().join("-");
        return new Intl.DateTimeFormat("en-GB").format(new Date(newDate));
      }else{
        //converte data in formato yyyy-mm-dd in dd/mm/yyyy
        return new Intl.DateTimeFormat("en-GB").format(new Date(value));
      }
    } else if (
      key === "quotaRendicontata" ||
      key === "quotaErogata" ||
      key === "cofinanziamento" ||
      key === "finanziamentoAssegnato"
    ) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    } else {
      return value;
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box display="flex" alignItems="center" m={2}>
            <Box flexGrow={1}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("DETTAGLI_PROGETTO")} {props.cellValues.id}:
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={props.handleClose}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#bf0202",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box m={3}>
            <Stack spacing={2}>
              {
              //lettura campi dettaglio progetto
              rows.length !== 0
                ? Object.keys(rows).map(
                    (key, i) =>
                      key !== "__typename" && (
                        <Box key={i} color="black">
                          <Typography
                            variant="h6"
                            component="span"
                            gutterBottom
                          >
                            {t(key.toUpperCase())}: <br />
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="span"
                          >
                            {rows[key] !== null
                              ? checkField(key, rows[key], rows.__typename)
                              : t("NON_DISPONIBILE")}
                          </Typography>
                        </Box>
                      )
                  )
                : null}
              {
              // display component Unità Operative (UO) ( SOLO PER PROGETTIRF )
              props.cellValues.row.__typename === "ProgettiRfProjection" && (
                <UO codiceProgetto={props.cellValues.id}></UO>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
