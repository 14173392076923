import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { createTheme, IconButton, ThemeProvider } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function MouseOverPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#06c",
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <IconButton
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <HelpOutlineIcon color="primary"></HelpOutlineIcon>
        </IconButton>
      </ThemeProvider>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "20%" },
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          variant="button"
          display="block"
          gutterBottom
          p={1}
          align="center"
        >
          {props.title}
        </Typography>

        <Typography
          variant="caption"
          display="block"
          gutterBottom
          p={1}
          align="center"
        >
          {props.description}
        </Typography>
      </Popover>
    </div>
  );
}
