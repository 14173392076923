import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import IRISTalkIMG from '../../../assets/iris_talk.png';

export default function IRISTalk() {

    function openLinkTalk(){
        const urlTalk = "https://apre-my.sharepoint.com/:v:/g/personal/debonis_apre_it/EbX47CWFs6lEv6eL65zsr2YBnq7i_gljAsyowvy2bNlk8w?e=TpzbUh"
        window.open(urlTalk, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>
        
        <Box>
            <Typography variant="body2" textAlign="justify">
                <br/>
                <strong>
                    IRIS TALK
                </strong><br/>
                Uno strumento "pilota" di comunicazione e networking per la Ricerca degli IRCCS 
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={IRISTalkIMG}
                width="100%"
            />
        </Box>

        <br/>

        <Box>
            <Typography variant="body2" textAlign="justify"  style={{fontSize:"small"}} >
                Progettato e lanciato come nuovo format a disposizione degli IRCCS per far conoscere le proprie attività e lanciarsi nel 
                panorama europeo della Ricerca e Innovazione, <b className="textHighlight" >IRIS Talk</b> è un prodotto video realizzato 
                da APRE che racconta in pochi minuti l'expertise di un gruppo di ricerca, i suoi risultati e la sua proposta di 
                collaborazione agli stakeholder nazionali ed europei.  
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography variant="body2" textAlign="justify"  style={{fontSize:"small"}} >
                Tra le prime esperienze di produzione di IRIS Talk, segnaliamo quella realizzato per l'IRCCS DON CARLO GNOCCHI intervistando:
                <ul key="talk">
                    <li key="talk1">
                        Marzia Bedoni, PhD, Research Team Leader Nanomedicine and Clinical Biophotonics Area
                    </li>
                </ul>
            </Typography>
        </Box>

        <br/>

        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center" role="button" onClick={()=>openLinkTalk()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Link a "IRIS Talk with Marzia Bedoni"
                </Typography>
            </Button>
        </Box>
        <br/>
        <hr/>
    </div>
  );
}
