import React, { Component } from "react";
import { Grid, Box, Container, Link, Typography } from "@mui/material";

export default class Footer extends Component {
  render() {
    return (
      <Box
        bgcolor="#036"
        color="white"
        px={3}
        py={5}
        textTransform="uppercase"
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Box borderBottom={1} mb={4}>
                <Typography variant="h6">WORKFLOW DELLA RICERCA</Typography>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://ricerca.cbim.it/index.html"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">WORKFLOW 2.0</Typography>
                </Link>
              </Box>
              {/* <Box mt={1} mb={1}>
                <Link
                  href="https://moh-it.pure.elsevier.com"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">
                    Scientific IRCCS Network su Scival
                  </Typography>
                </Link>
              </Box> */}
              <Box mt={1} mb={1}>
                <Link
                  href="https://hd.cbim.it"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">HelpDesk</Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box borderBottom={1} mb={4}>
                <Typography variant="h6">Ministero Della Salute</Typography>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.salute.gov.it/portale/home.html"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">Sito Web</Typography>
                </Link>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.salute.gov.it/portale/news/p3_2_1.jsp?lingua=italiano&menu=notizie&area=Ricerca%20sanitaria"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">Notizie</Typography>
                </Link>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.salute.gov.it/portale/p5_0.jsp?lingua=italiano&id=58"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">Contatti</Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box borderBottom={1} mb={4}>
                <Typography variant="h6">CBIM</Typography>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.cbim.it/"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">Sito Web</Typography>
                </Link>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.cbim.it/dove-siamo_new/index.html"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">Sede</Typography>
                </Link>
              </Box>
              <Box mt={1} mb={1}>
                <Link
                  href="http://www.cbim.it/workflow-della-ricerca-biomedica_new/index.html"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  <Typography variant="subtitle1">
                    Workflow della Ricerca Biomedica
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" pt={5} pb={4}>
            Copyright © {(new Date().getFullYear())} Consorzio di Bioingegneria e Informatica Medica.
            All rights reserved. P.IVA 01515320180
          </Box>
          <Box textAlign="center">
            <Link
              href="http://www.mxguarddog.com/exchange"
              color="inherit"
              underline="hover"
              target="_blank"
            >
              <Typography variant="subtitle1">free spam filter</Typography>
            </Link>
          </Box>
        </Container>
      </Box>
    );
  }
}
