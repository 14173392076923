import React from "react";
import {
  Box,
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Divider,
  Link,
} from "@mui/material";
import "./IRIS.css";
import { NavLink } from "react-router-dom";

export default function IRISArchivio() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={2}
              pl={12}
              pr={12}
            >
              <img
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/79405f16-1688-4d88-a469-692bc2a52fa8.jpg"
                width="100%"
              ></img>
            </Box>
            <Box bgcolor="#EA5B3A" textAlign="center">
              <Typography variant="h5" pt={2} pb={2} color="white">
                <strong>ARCHIVIO</strong>
              </Typography>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Il Magazine dedicato agli&nbsp;IRCCS
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    textAlign="justify"
                    color="#656565"
                  >
                    Tra i prodotti delle attività di supporto alla comunicazione
                    interna, anche il Magazine trimestrale{" "}
                    <strong>IRIS Mag'</strong>, strumento informativo su
                    indirizzi di policy a livello europeo in tema Salute,
                    iniziative ed eventi nazionali ed internazionali di
                    rilevanza per la ricerca sanitaria.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/3370873e-4e54-42e8-b5b7-55c2b5fa79b3.jpg"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#2FC399",
                    }}
                    href="https://mailchi.mp/103a1ef8eaa9/iris-magazine-1-dicembre-2020"
                    target="_blank"
                  >
                    Download IRIS Mag'3
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    3 Webinar "IRIS&nbsp;Verso Horizon Europe"
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    textAlign="justify"
                    color="#656565"
                  >
                    Tra i servizi offerti, l’iniziativa IRIS prevede un’azione
                    di capacity building mirata, legata alle esigenze
                    informative, formative e di assistenza degli IRCCS.&nbsp;
                    <br></br>
                    Il percorso formativo <em>IRIS Verso Horizon Europe</em>,
                    tra Novembre 2020 e Gennaio 2021, ha contribuito a preparare
                    gli IRCCS ad affrontare le nuove sfide poste dal nuovo
                    Programma Quadro europeo per la Ricerca e l’Innovazione per
                    il periodo 2021-2027 “Horizon Europe”, attraverso{" "}
                    <strong>3 appuntamenti formativi online:&nbsp;</strong>
                  </Typography>
                  <Box pl={4} pt={1} pb={1}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="justify"
                      color="#656565"
                    >
                      16 NOVEMBRE 2020, 15.00 - 16.30: IRIS verso Horizon Europe
                    </Typography>

                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="justify"
                      color="#656565"
                    >
                      10 DICEMBRE 2020, 14.30 - 17.00: Horizon Europe: le
                      opportunità per il settore Salute
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="justify"
                      color="#656565"
                    >
                      13 GENNAIO 2021, 14.30 - 16.30: L’Implementation Strategy
                      e il Model Grant Agreement di Horizon Europe
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    textAlign="justify"
                    color="#656565"
                  >
                    Per maggiori informazioni, è possibile consultare la pagina
                    dedicata:&nbsp;
                    <Link
                      color="inherit"
                      href="https://www.apre.it/eventi/iris/webinar-iris-verso-horizon-europe"
                      target="_blank"
                    >
                      https://www.apre.it/eventi/iris/webinar-iris-verso-horizon-europe&nbsp;
                    </Link>
                    <br></br>
                    <strong>
                      Di seguito, è possibile scaricare il materiale presentato
                      in occasione dei webinar.&nbsp;
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/5a70d520-ed30-41af-a2bf-8839888206ff.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#CB3475",
                    }}
                    href="https://mcusercontent.com/523af143bc18413a060734433/files/12fa14d7-d4d3-41e9-8148-234e0d3b3a83/1_Webinar_IRIS_Verso_Horizon_Europe_16_nov_2020.pdf"
                    target="_blank"
                  >
                    [Materiali] Webinar 16 Novembre 2020
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#CB3475",
                    }}
                    href="https://mcusercontent.com/523af143bc18413a060734433/files/4b2d0265-edb9-42c1-ab53-1719d6f0be6b/webinar_IRIS_verso_HE_10_dic_2020_compressed.pdf"
                    target="_blank"
                  >
                    [Materiali] Webinar 10 Dicembre 2020
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#CB3475",
                    }}
                    href="https://mcusercontent.com/523af143bc18413a060734433/files/1ace4d0b-e9c7-4933-8c0e-9ac891ac5d61/IRIS_webinar_L_F_13_gennaio_2021.pdf"
                    target="_blank"
                  >
                    [Materiali] Webinar 13 Gennaio 2021
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box pb={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box bgcolor="#EA5B3A" textAlign="center">
                    <Typography variant="h5" pt={2} pb={2} color="white">
                      <Link
                        color="inherit"
                        href="https://mailchi.mp/aad63294afd2/iris-archivio-2019-2020"
                        target="_blank"
                      >
                        Archivio 2019-2020
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} textAlign="center" pt={1} pb={1}>
                  <strong>
                    Torna alla <NavLink to="/iris">HOME</NavLink>
                  </strong>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    bgcolor="#ebebeb"
                    textAlign="center"
                    pl={4}
                    pr={4}
                    pt={3}
                    pb={2}
                  >
                    <Typography variant="caption" color="#656565">
                      <em>
                        Copyright © IRIS 2019, Ministero della Salute. All
                        rights reserved.
                      </em>
                      <br></br>
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      <strong>
                        Iniziativa finanziata dal Ministero della Salute:
                      </strong>
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      Servizio di “Supporto agli Istituti di ricovero e cura a
                      carattere scientifico (IRCCS) per
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      l’internazionalizzazione della ricerca – Anni 2020 e 2021”
                      (CIG 8220564005)
                      <br></br>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
