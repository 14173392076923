import { gql } from "@apollo/client";

export const GET_DI = gql`
  query {
    getDI {
      id
      nomeBreve
      sigla
    }
  }
`;

export const GET_ISTITUTI = gql`
  query {
    getIstituti {
      id
      nomeBreve
      sigla
    }
  }
`;

export const GET_IRG = gql`
  query {
    getNihIrg {
      id
      anno
      descrizione
      sigla
    }
  }
`;

export const GET_LINEE_DI_RICERCA = gql`
  query getLineediricerca($anno: BigDecimal, $codice_istituto: BigDecimal) {
    getLineediricerca(anno: $anno, codice_istituto: $codice_istituto) {
      id
      anno
      progressivo
      titolo
      codice_istituto
    }
  }
`;

export const GET_PROGETTI_RF = gql`
  query getProgettiRf(
    $anno: BigDecimal
    $codiceProgetto: String
    $idIstituto: BigDecimal
    # $nomeResponsabile: String
    # $cognomeResponsabile: String
    $titolo: String
    $keyword: String
    $irgId: BigDecimal
  ) {
    getProgettiRf(
      anno: $anno
      codiceProgetto: $codiceProgetto
      istituto: $idIstituto
      # nomeResponsabile: $nomeResponsabile
      # cognomeResponsabile: $cognomeResponsabile
      titolo: $titolo
      keyword: $keyword
      irgId: $irgId
    ) {
      codiceProgetto
      # nomeResponsabile
      # cognomeResponsabile
      anno
      istituto
      titolo
    }
  }
`;

export const GET_PROGETTI_RC = gql`
  query getProgettiRc(
    $anno: BigDecimal
    $codiceProgettoWF: BigDecimal
    $idIstituto: BigDecimal
    # $nomeResponsabile: String
    # $cognomeResponsabile: String
    $titolo: String
    $paroleChiave: String
    $idLineaDiRicerca: BigDecimal
  ) {
    getProgettiRc(
      annoLinea: $anno
      idProgetto: $codiceProgettoWF
      idIstituto: $idIstituto
      # nomeResponsabile: $nomeResponsabile
      # cognomeResponsabile: $cognomeResponsabile
      titolo: $titolo
      paroleChiave: $paroleChiave
      idLineaDiRicerca: $idLineaDiRicerca
    ) {
      idProgetto
      # nomeResponsabile
      # cognomeResponsabile
      istituto
      annoLinea
      lineaDiRicerca
      titolo
    }
  }
`;

export const GET_PROGETTI_RC_RETI = gql`
  query getProgettiRcReti(
    $anno: BigDecimal
    $istituto: BigDecimal
    $codiceCup: String
    $titolo: String
  ) {
    getProgettiRcReti(
      anno: $anno
      codiceIstituto: $istituto
      codiceCup: $codiceCup
      titolo: $titolo
    ) {
      idProgetto
      codice
      nomeBreve
      anno
      titolo
      codiceCup
    }
  }
`;

export const GET_PROGETTI_CC = gql`
  query getProgettiCc(
    $anno: BigDecimal
    $idIstituto: BigDecimal
    $codiceCup: String
    $titolo: String
  ) {
    getProgettiCc(
      anno: $anno
      codiceIstituto: $idIstituto
      codiceCup: $codiceCup
      titolo: $titolo
    ) {
      idProgetto
      codice
      nomeBreve
      anno
      titolo
      codiceCup
    }
  }
`;

export const GET_PROGETTI_5X1000 = gql`
  query getProgetti5x1000(
    $anno: BigDecimal
    $idIstituto: BigDecimal
    $titolo: String
  ) {
    getProgetti5x1000(
      anno: $anno
      codiceIstituto: $idIstituto
      titoloProg: $titolo
    ) {
      codiceProgetto
      ente
      anno
      titoloProg
      idProgetto
    }
  }
`;

export const GET_PROGETTO_RF_BY_CODICE = gql`
  query getProgettoRfByCodice($id: String) {
    getProgettoRfByCodice(codiceProgetto: $id) {
      titolo
      statoProgetto
      istitutoEsteso
    	codiceCup
      tipologia
      finanziamentoAssegnato
      quotaErogata
      quotaRendicontata
      irg
      studySection
      keyword
      otherKeywords
      # nomeResponsabile
      # cognomeResponsabile
      anno
      descrizione
      summary
      background
      hypotesisSignificance
      significanceInnovation
      translationalRelevanceImpact
      bibliografia
      dataInizio
      dataFine
      dataProroga
    	specificAim1
    	specificAim2
    	specificAim3
    }
  }
`;

export const GET_PROGETTO_RC_BY_ID = gql`
  query getProgettoRcById($id: BigDecimal) {
    getProgettoRcById(idProgetto: $id) {
      titolo
      istituto
      lineaDiRicerca
      programma
      areaInteresse
      tipologia
      altreStruttureCoinvolte
      altriEntiCoinvolti
      drg
      paroleChiave
      # nomeResponsabile
      # cognomeResponsabile
      annoInizio
      durata
      attivitaPreviste
      risultati
      irg
      studySection
      background
      hypotesisSignificance
      specificAims
      expectedOutcome
      significanceInnovation
      translationalRelevanceImpact
    }
  }
`;

export const GET_PROGETTO_RC_RETI_BY_ID = gql`
  query getProgettoRcRetiById($id: BigDecimal) {
    getProgettoRcRetiById(idProgetto: $id) {
      anno
      codice
      titolo
    	statoProgetto
      nomeBreve
      codiceCup
      dataCup
      dataInizio
      dataFine
      dataProroga
      cofinanziamento
    	quotaErogata
    	quotaRendicontata
    	
    }
  }
`;

export const GET_PROGETTO_CC_BY_ID = gql`
  query getProgettoCcById($id: BigDecimal) {
    getProgettoCcById(idProgetto: $id) {
      anno
      codice
      titolo
    	statoProgetto
      nomeBreve
      codiceCup
      dataCup
      dataInizio
      dataFine
      dataProroga
      cofinanziamento
    	quotaErogata
    	quotaRendicontata
    	
    }
  }
`;

export const GET_PROGETTO_5X1000_DETTAGLIO = gql`
  query getProgetto5x1000Dettaglio(
    $codiceProgetto: String
    $idProgetto: BigDecimal
  ) {
    getProgetto5x1000Dettaglio(
      codiceProgetto: $codiceProgetto
      idProgetto: $idProgetto
    ) {
      codiceProgetto
      anno
      titoloProg
    	statoProgetto
      ente
      cfIstituto
      sedeLegaleIst
      codiceCup
      pecEnte
      abstractProg
      dataInizioProg
      dataFineProg
      quotaProgetto
      dataRicFondi
    	# quotaErogata
    	# quotaRendicontata
    }
  }
`;

export const GET_PROGETTO_5X1000_DETTAGLIO_QUOTA = gql`
  query getProgetti5x1000DettaglioQuote($idProgetto: BigDecimal) {
    getProgetti5x1000DettaglioQuote(idProgetto: $idProgetto) {
      idProgetto
      voceCosto
      quotaSostenuta
      quotaAccantonata
    }
  }
`;

export const GET_UO = gql`
  query getUO($codiceProgetto: String) {
    getUO(codiceProgetto: $codiceProgetto) {
      istituto
    }
  }
`;

export const GET_PUBBLICAZIONI = gql`
  query getPubblicazioni(
    $anno: BigDecimal
    $idIstituto: BigDecimal
    $idPmid: BigDecimal
    $doi: String
    $idLineaDiRicerca: BigDecimal
    $titolo: String
    $rawData: String
  ) {
    getPubblicazioni(
      anno: $anno
      idIstituto: $idIstituto
      idPmid: $idPmid
      doi: $doi
      idLineaDiRicerca: $idLineaDiRicerca
      titolo: $titolo
      rawData: $rawData
    ) {
      idPubblicazione
      anno
      titolo
      doi
      fulltextUrl
      pmid
      istituto
      lineaDiRicerca
      rivista
      volume
      pagine
      autori
      rawData
    }
  }
`;

export const GET_AUTORI_PUBBLICAZIONE = gql`
  query getAutoriByCodicePubblicazione($codicePubblicazione: BigDecimal) {
    getAutoriByCodicePubblicazione(codicePubblicazione: $codicePubblicazione) {
      posizione
      autore
    }
  }
`;
