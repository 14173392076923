import React from "react";
import { Box, Typography } from "@mui/material";
import "../IRIS.css";

export default function IRISintestazione() {
  return (
    <div>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/6248db66-869e-46eb-87c7-61eac936a3b3.png"
                width="100%"
            />
            
        </Box>
        
        <hr/>
        <br/>
        
        <Box style={{backgroundColor:"#8B8484",color:"#F2F2F2",padding:"1em"}}>
            <Typography variant="body2" gutterBottom textAlign="justify">
                Si è chiusa il 1° Settembre 2022 l’iniziativa denominata “IRIS – a supporto dell’Internazionalizzazione della Ricerca italiana in Sanità”, 
                implementata per conto del Ministero della Salute da APRE – Agenzia per la Promozione della Ricerca Europea – 
                nell’ambito del Servizio di “Supporto agli Istituti di ricovero e cura a carattere scientifico (IRCCS) 
                per l’internazionalizzazione della ricerca” (CIG n. 8220564005).
            </Typography>
        </Box>
    </div>
  );
}
