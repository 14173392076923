import React from "react";
import { Box, Typography } from "@mui/material";
import "../IRIS.css";
import ServiziOffertiIMG from '../../../assets/servizi_offerti.jpg';

export default function IRISServiziOfferti() {
  return (
    <div>
        
        <Box bgcolor="#EA5B3A" textAlign="center">
            <Typography variant="body1" pt={2} pb={2} color="white">
                I SERVIZI OFFERTI
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={ServiziOffertiIMG}
                width="100%"
            />
        </Box>

        <br/>
        
        <Box>
            <Typography style={{fontSize:"small"}} textAlign="justify" className="textGrey" >
                IRIS ha operato a <b>supporto del sistema-IRCCS mirando a rafforzarne la partecipazione nel passaggio tra Horizon 
                2020 e Horizon Europe</b>, il nuovo Programma Quadro Europeo per la Ricerca e l’Innovazione, attraverso il 
                potenziamento delle competenze degli uffici addetti alla gestione dei Grant europei e il miglioramento del lavoro 
                di rete tra IRCCS a livello nazionale.
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                I servizi offerti nell’ambito di IRIS sono stati erogati a partire da Settembre 2020, 
                e hanno riguardato lo sviluppo di attività di <b>formazione, informazione, networking e brokerage, 
                nonché l’analisi dei dati sulla partecipazione degli IRCCS al Programma Quadro europeo per la Ricerca e l’Innovazione.</b>
                Un’analisi periodica del livello di soddisfazione attraverso il confronto diretto con i beneficiari dei 
                servizi (e attraverso i Focus Group dei Grant Office degli IRCCS) ha consentito, in fase di valutazione 
                intermedia, di apportare progressivamente aggiustamenti rispetto alla pianificazione iniziale, 
                nonché di raccogliere feedback utili ad elaborare via via proposte migliorative dei servizi stessi.
            </Typography>
        </Box>

    </div>
  );
}
