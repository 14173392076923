import { Box, Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./AreaPubblicazioni.css";
import FormPubblicazioni from "../FormPubblicazioni/FormPubblicazioni";

// component che svolge la funzione di contenitore dell'area pubblicazioni
export default function AreaPubblicazioni() {
  const { t } = useTranslation();

  return (
    <Box pt={5} pb={5} pl={2} pr={2} bgcolor="#eeeeee">
      <Card>
        <CardHeader title={<strong>{t("PUBLICATIONS")}</strong>} subheader={t("DESCR_PROJECTS")} />
        <CardContent sx={{
          mr:2,
          ml:2,
        }}>
            <FormPubblicazioni></FormPubblicazioni>
        </CardContent>
      </Card>
    </Box>
  );
}
