import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import BrokerageIMG from '../../../assets/brokerage.png';

export default function IRISBrokerage() {

    function openLinkReportBrokerage(){
        const urlReport = "https://mcusercontent.com/523af143bc18413a060734433/files/baadd381-08df-5524-07cc-e68d31f2be52/APRE_13_e_14_luglio_Report_visual_.pdf"
        window.open(urlReport, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>
       <Box>
            <Typography variant="body2" textAlign="justify">
                <strong>EVENTI DI BROKERAGE</strong>
                <br/>
                La Ricerca incontra l'Industria
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={BrokerageIMG}
                width="100%"
            />
        </Box>
        
        <br/>

        <Box>
            <Typography variant="body2" textAlign="justify" className="textGrey"  style={{fontSize:"small"}}  >
                L’iniziativa, a cura di APRE, organizzata in collaborazione con Farmindustria e Confindustria Dispositivi Medici,  
                si è svolta presso il Ministero della Salute (Auditorium, Viale Giorgio Ribotta 5, 00144 Roma) nelle giornate del 
                13 luglio (h. 9:00 – 17:00) e 14 luglio (h. 9:00 – 13:00).<br/>
                Entrambe le giornate del <b className="textHighlight" >13 luglio e 14 luglio</b> , rispettivamente dedicate all’incontro 
                della ricerca con l’industria farmaceutica e dei dispositivi per la diagnostica, sono state strutturate come segue:  
            </Typography>
        </Box>

        <br/>

        <Box style={{backgroundColor:"#EEAB77",color:"#F2F2F2",padding:"1em",fontSize:"small"}} >
            <Typography variant="body2" gutterBottom textAlign="justify">
                <ul key="br">
                    <li key="br1">
                        Saluti istituzionali
                    </li>
                    <li key="br2">
                        [Sessione 1] Tavola Rotonda: Il valore della partnership pubblico-privato per creare innovazione in sanità
                    </li>
                    <li key="he3">
                        [Sessione 2] L’incontro tra Ricerca e Industria
                    </li>
                    <li key="he4">
                        Pitch: brevi interventi (max. 5 minuti) in grado di presentare l’offerta di collaborazione della Ricerca in sessione plenaria
                    </li>
                    <li key="he5">
                        Incontri bilaterali Ricerca/Industria
                    </li>
                </ul>
            </Typography>
        </Box>

        <br/>
        <hr/>

        <Box>
            <Typography variant="body2" textAlign="justify" className="textGrey"  style={{fontSize:"small"}}  >
                In particolare, la Tavola Rotonda – per entrambe le giornate – è stata dedicata al confronto tra gli attori R&I e 
                le istituzioni sui temi della <b>valorizzazione dei risultati e del trasferimento tecnologico</b>. 
                Un panel di esperti provenienti dal mondo della ricerca pubblica, delle start-up e dell’industria farmaceutica e dei 
                dispositivi medici ha accompagnato  la presentazione delle azioni delle istituzioni a supporto del trasferimento tecnologico, 
                fornendo degli spunti di riflessione per evidenziare le peculiarità dell'interazione tra il mondo della ricerca sanitaria e 
                l'industria, e per delineare possibili prospettive di partnership in ambito nazionale ed europeo.
                <br/>
                La seconda sessione, invece, dedicata all’<b>incontro tra Ricerca e Industria</b>, ha previsto una sessione in plenaria in cui,
                con brevi interventi (pitch di 5 minuti) sono state presentate le offerte di collaborazione della Ricerca nei confronti 
                dell’industria farmaceutica/dei dispositivi medici. È seguito, poi, uno spazio riservato alla realizzazione d’incontri bilaterali 
                presso l’Area Congressuale del Ministero della Salute, già pianificati e/o spontanei. 
            </Typography>
        </Box>

        <br/>

        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center" role="button" onClick={()=>openLinkReportBrokerage()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Scarica il resoconto dell'iniziativa di brokerage
                </Typography>
            </Button>
        </Box>

        <br/>
        <hr/>

    </div>
  );
}
