import React from "react";
import {
  Box,
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import "./IRIS.css";
import { NavLink } from "react-router-dom";

export default function IRISComunicazioni() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={2}
              pl={4}
              pr={4}
            >
              <img
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/5da22e83-b11a-4647-ab97-806350974a4c.jpg"
                width="100%"
              ></img>
            </Box>
            <Box bgcolor="#86D1B0" textAlign="center">
              <Typography variant="h5" pt={2} pb={2} color="white">
                <strong>COMUNICAZIONI</strong>
              </Typography>
            </Box>
            <Box pl={18} pr={18}>
              <img
                align="center"
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/ca29ac81-9901-48ed-8153-c1f15f01a688.png"
                width="100%"
              ></img>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box bgcolor="#EA5B3A" textAlign="center">
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6" pt={2} pb={2} color="white">
                    <strong>IRIS: NUOVI CORSI DI FORMAZIONE IN AULA!</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/a48a621e-bfaf-4b0c-89e4-07a3018e6821.jpg"
                    width="100%"
                    height="100%"
                  ></img>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    A completamento dell’azione di capacity building avviata con
                    i Webinar erogati tra Marzo e Aprile 2019, IRIS propone il
                    corso{" "}
                    <strong>
                      “Elementi di progettazione ed aspetti legali e finanziari
                      in H2020 + <em>focus</em>:&nbsp;Comunicazione e
                      disseminazione in H2020 ovvero come massimizzare
                      l’Impatto"
                    </strong>
                    , che si svolgerà in 2 giornate&nbsp;a{" "}
                    <strong>Milano </strong>(
                    <span style={{ color: "#ff6633" }}>
                      <strong>16 e 17&nbsp;Ottobre 2019</strong>
                    </span>
                    ) e, in replica, a <strong>Roma </strong>(
                    <strong>
                      <span style={{ color: "#ff6633" }}>
                        22 e 23&nbsp;Ottobre 2019
                      </span>
                    </strong>
                    ).<br></br>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    La partecipazione all’evento è <strong>gratuita</strong>
                    &nbsp;(le
                    <font face="arial, helvetica neue, helvetica, sans-serif">
                      &nbsp;spese di viaggio e alloggio sono a carico dei
                      partecipanti)&nbsp;
                    </font>
                    ed è <strong>riservata</strong> ai Ricercatori e al
                    personale di supporto alla Ricerca degli IRCCS
                    (preferibilmente, referenti dei Grant Office), per un{" "}
                    <strong>max. 150 utenti, previa iscrizione.</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#EA5B3A",
                    }}
                    href="https://www.apre.it/eventi/iris/corso-iris-milano/"
                    target="_blank"
                  >
                    Iscriviti al Corso che si terrà a Milano!
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#EA5B3A",
                    }}
                    href="https://www.apre.it/eventi/iris/corso-iris-roma/"
                    target="_blank"
                  >
                    Iscriviti al Corso che si terrà a Roma!
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/63950ccb-49de-4c6a-b1d7-f67c87c71f4d.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={7}>
                  <Typography variant="h4" gutterBottom>
                    <strong>BROKERAGE EVENT</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Tra i suoi servizi, IRIS prevede anche l'organizzazione di
                    incontri B2B tra IRCCS e aziende, finalizzati al
                    trasferimento tecnologico e alla valorizzazione dei
                    risultati della ricerca nell’ottica della partecipazione ai
                    bandi europei<br></br>
                    <strong>IRIS BROKERAGE EVENT </strong>si terrà il{" "}
                    <strong>
                      <span style={{ color: "#ff0066" }}>29 Ottobre 2019</span>{" "}
                    </strong>
                    presso il Ministero della Salute (Viale G. Ribotta, 5 –
                    00144 Roma) e sarà un opportunità preziosa per il sistema
                    IRCCS per incontrare il mondo dell'industria legato al
                    settore farmaceutico e biomedicale.<br></br>
                    <br></br>
                    La partecipazione all'evento è gratuita. Le spese di viaggio
                    e alloggio sono a carico dei partecipanti.
                  </Typography>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#C71956",
                    }}
                    href="https://iris-brokerage-event.b2match.io/home"
                    target="_blank"
                  >
                    Registrati qui!
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box bgcolor="#C2970A" textAlign="center">
              <Grid container>
                <Grid item xs={6}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/cb7486bd-c001-40cf-ba98-7324f63dbd87.jpg"
                    width="100%"
                    height="100%"
                  ></img>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" pt={2} pb={2} color="white">
                    <strong>
                      L'OFFERTA FORMATIVA DI IRIS PER I GRANT OFFICE DEGLI IRCCS
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    IRIS intende offrire ai Grant Officer degli IRCCS{" "}
                    <strong>un'opportunità esclusiva di formazione</strong> in
                    cui sarà possibile confrontarsi direttamente con i National
                    Contact Point H2020 di APRE nello svolgimento di
                    esercitazioni pratiche che consentiranno di mettere&nbsp;a
                    frutto le conoscenze finora acquisite&nbsp;nell'ambito
                    dell'iniziativa del Ministero della Salute, per la scrittura
                    di una proposta H2020 di successo.<br></br>
                    <br></br>
                    Pertanto, IRIS propone il Corso{" "}
                    <strong>“Imparare dalla valutazione” </strong>che si
                    svolgerà a Roma il{" "}
                    <span style={{color:"#cc6600"}}>
                      <strong>28 Novembre 2019</strong>&nbsp;
                    </span>
                    quale necessario momento di approfondimento delle nozioni
                    apprese,&nbsp;basato sull’esercitazione e sul principio del{" "}
                    <em>learning by doing.</em>
                    <br></br>
                    <br></br>
                    La partecipazione all’evento è <strong>gratuita</strong> ed
                    è <strong>riservata</strong>&nbsp; al personale di supporto
                    alla Ricerca degli IRCCS (preferibilmente, referenti dei
                    Grant Office) che ha partecipato al corso&nbsp;
                      “Elementi di progettazione ed aspetti legali e finanziari
                      in H2020”
                    , per un <strong>max. 51 utenti, previa iscrizione.</strong>
                  </Typography>
                </Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#C2970A",
                    }}
                    href="https://www.apre.it/eventi/iris/corso-iris-roma-(esercitazione)/"
                    target="_blank"
                  >
                    Iscriviti!
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} textAlign="center" pt={1} pb={1}>
                  <strong>
                    Torna alla <NavLink to="/iris">HOME</NavLink>
                  </strong>
                </Grid>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
