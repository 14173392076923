import React from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import "./BussoleIRCSS.css";
import { Link } from "react-router-dom";
import bussoleLogo from "../../assets/bussole-irccs-logo.png";

export default function BussoleIRCSS() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={2}
            >
              <img alt="" src={bussoleLogo} width="100%"></img>
            </Box>
            <Box>
              <Typography variant="body2" gutterBottom>
                Lo sviluppo scientifico e tecnologico sta vivendo, in questi
                anni, un momento di importante evoluzione, le cui implicazioni
                in ambito sanitario, economico, etico e sociale diventano sempre
                più concrete. La consapevolezza del potenziale che tale
                dinamicità rappresenta per la ricerca scientifica del Paese
                assume, pertanto, una centralità inedita e sottolinea la
                necessità di rafforzare le competenze e di organizzarle secondo
                una visione coordinata, omogenea e condivisa.<br></br>
                <br></br>{" "}
              </Typography>
              <Typography variant="body2" gutterBottom>
                In tale scenario, gli Istituti di Ricovero e Cura a Carattere
                Scientifico (IRCCS), quali poli di eccellenza e centri di
                riferimento per le attività di ricerca e di assistenza, sono
                investiti di una responsabilità particolare all’interno del
                panorama scientifico nazionale, in quanto rappresentano uno
                strumento dinamico per la promozione, lo sviluppo e
                l’applicazione di tecnologie innovative in ambito sanitario e
                biomedico. In tale contesto, diventa sempre più urgente ed
                importante che la rete degli IRCCS agisca come un unico sistema
                e per far ciò necessita di strumenti condivisi, procedure
                omogenee e linguaggio comune<br></br>
                <br></br>
              </Typography>
              <Typography variant="body2" gutterBottom>
                E’ proprio questo lo scenario di fermento scientifico e
                culturale che ha ispirato la genesi della collana denominata le
                “Bussole IRCCS” composta da piccoli volumi, dal taglio
                operativo, concepiti con l’obiettivo di fornire un orientamento
                condiviso, studiato, replicabile ed esportabile. I volumi
                saranno dedicati all’approfondimento di tematiche significative
                per il mondo della ricerca sanitaria che verranno man mano
                sviscerate, approfondite e costantemente aggiornate da tavoli
                IRCCS istituiti ad hoc. Il lavoro avviato dai predetti tavoli
                tematici rappresenta un’opportunità preziosa e insostituibile
                per arricchire, confrontare, e consolidare le competenze,
                creando un patrimonio dinamico in costante aggiornamento. Le
                Bussole IRCCS costituiscono, quindi, uno strumento di sintesi
                conseguito attraverso un confronto interno volto al superamento
                delle disomogeneità procedurali, che raccoglie le esperienze
                operative della Rete IRCCS e offre un punto di partenza per un
                percorso di qualità.<br></br>
                <br></br>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Stack spacing={2} pl={25} pr={25}>
                <Button
                  component={Link}
                  to="/bussole-ircss/archivio"
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#EA5B3A",
                  }}
                >
                  ARCHIVIO
                </Button>
                <Box pb={2}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/79ee5794-a601-45e2-b9d6-e7500ee0f547.jpg"
                    width="100%"
                  ></img>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
