import React from "react";
import {
  Box,
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import "./IRIS.css";
import { NavLink } from "react-router-dom";

export default function IRISServizi() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={2}
            >
              <img
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/6248db66-869e-46eb-87c7-61eac936a3b3.png"
                width="100%"
              ></img>
            </Box>
            <Box bgcolor="#E0629A" textAlign="center">
              <Typography variant="h5" pt={2} pb={2} color="white">
                <strong>SERVIZI PER GLI IRCCS</strong>
              </Typography>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container>
                <Grid item xs={4}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/e722dd2e-3bd5-462e-b907-cd08375c7e59.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography variant="h6" gutterBottom>
                    E-Alert sulle opportunità europee di interesse della ricerca
                    sanitaria
                  </Typography>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Il{" "}
                    <span style={{ color: "#ff6600" }}>
                      <strong>Servizio trimestrale&nbsp;di e-ALERT</strong>
                    </span>{" "}
                    fornisce una Panoramica delle opportunità di finanziamento
                    per la Ricerca a livello europeo, riportando le informazioni
                    utili ad identificare le Call for Proposals in ordine di{" "}
                    <em>Scadenza</em>, per <em>Topic</em> e{" "}
                    <em>Tipo di Azione,</em> e suddividendo i bandi di interesse
                    per Programma di Finanziamento:
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <Typography variant="subtitle1">
                    Horizon 2020 – Societal Challenges
                  </Typography>
                  <Typography variant="subtitle1">
                    Horizon 2020 - Altri Pilastri/Iniziative/Programmi
                    Trasversali/Programmi Complementari
                  </Typography>
                  <Typography variant="subtitle1">
                    ERANet e Joint Programming Initiatives
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Altri Programmi europei di finanziamento
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" textAlign="justify" gutterBottom>
                    Nel caso specifico di Call for Proposals a brevissima
                    scadenza, è possibile consultare le opportunità di
                    partenariato nella sezione{" "}
                    <strong>
                      <em>Partner Search</em>
                    </strong>{" "}
                    presente nelle Schede Informative (
                    <strong>
                      <em>Call Fiches</em>
                    </strong>
                    ) relative ai singoli Topic, in coda al documento,
                    singolarmente consultabili cliccando sui codici
                    identificativi (ID) di ciascun Topic.&nbsp;Il documento
                    e-ALERT include le informazioni su eventuali{" "}
                    <strong>sinergie</strong> con altri Programmi europei di
                    finanziamento di rilevanza per la ricerca sanitaria.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" textAlign="justify" mt={1}>
                    <span style={{ color: "#ff6600" }}>
                      <strong>[!] </strong>
                    </span>
                    Il servizio trimestrale di e-ALERT sarà integrato dall'invio
                    tempestivo di&nbsp;<em>fiches</em> informative
                    esclusivamente dedicate alle{" "}
                    <span style={{ color: "#ff6600" }}>
                      <strong>European Partnership</strong>
                    </span>{" "}
                    di agevole comprensione per i Ricercatori, messe a
                    disposizione degli uffici Grant degli IRCCS, in grado di
                    chiarire i termini della partecipazione degli
                    Istituti&nbsp;nel quadro dei nuovi Partenariati europei e
                    rispetto al coinvolgimento del Ministero della Salute e
                    degli altri enti erogatori nazionali.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Typography variant="h6" gutterBottom>
                    Incontri B2B tra IRCCS e altri centri di ricerca e imprese
                  </Typography>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Alla mappatura delle opportunità di B2B tra IRCCS, altri
                    enti di ricerca e la componente industriale - che verranno
                    promosse attraverso i servizi informativi - si affiancherà
                    &nbsp;un percorso di coinvolgimento degli IRCCS volto alla
                    realizzazione di "IRIS BROKERAGE EVENTS”, basati sulla
                    ricognizione dei progetti e dei risultati della ricerca.
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/5744b3a2-ca51-4148-8db1-7261b9013ab9.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Typography variant="body1" gutterBottom textAlign="justify">
                  Tale percorso prevederà&nbsp;attività preparatorie, di
                  organizzazione e di valutazione degli eventi di brokeraggio
                  “IRIS”, realizzati
                  <strong>
                    &nbsp;in&nbsp;sinergia con i Technology Trasfer Office - TTO
                    e Grant Office – GO degli IRCCS
                  </strong>
                  ,&nbsp;coinvolti nella ricognizione degli input e dei feedback
                  dei propri ricercatori, per una più mirata interlocuzione con
                  la componente industriale.&nbsp;<br></br>
                  Si prevede, in particolare, l'organizzazione di{" "}
                  <span style={{ color: "#ff6600" }}>
                    <strong>2 eventi di brokerage tematici</strong>
                  </span>{" "}
                  (2021, 2022) targati IRIS.
                </Typography>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/45122af6-9a7d-4a1d-afb2-c1b5e22b2058.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography variant="h6" gutterBottom>
                    Capacity Building
                  </Typography>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Il Servizio è dedicato ai ricercatori ed al personale di
                    supporto alla Ricerca degli IRCCS e fornisce
                    Informazione/Formazione sui Programmi di finanziamento
                    europei.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Il Servizio include anche il supporto alla preparazione
                    delle proposte di progetto, e alla conduzione
                    tecnico-scientifica e amministrativo-contabile dei progetti
                    europei (
                    <span style={{ color: "#ff6600" }}>
                      <strong>IRIS HelpDesk</strong>
                    </span>
                    ). La formazione - in modalità mista (blended) - prevede
                    l'erogazione di{" "}
                    <span style={{ color: "#ff6600" }}>
                      <strong>Webinar </strong>
                    </span>
                    e
                    <strong>
                      {" "}
                      <span style={{ color: "#ff6600" }}>Corsi in aula</span>
                      .&nbsp;
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Divider></Divider>
                  <Typography
                    variant="body1"
                    gutterBottom
                    textAlign="justify"
                    mt={1}
                  >
                    Coinvolgendo i Grant Office degli IRCCS nell’ambito di{" "}
                    <strong>Gruppi di Lavoro</strong> dedicati, APRE coordinerà
                    un' attività di ricognizione periodica delle esigenze
                    formative/informative/di assistenza, sviluppando un'offerta
                    formativa modulabile, tarata sul percorso attraverso il
                    nuovo Programma Quadro <strong>Horizon Europe</strong> e
                    rivolta a Ricercatori e personale di supporto alla ricerca.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/1d264e33-fe4b-49d4-ba9a-91b0d5bf2903.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    style={{
                      backgroundColor: "#3FA79B",
                    }}
                    href="https://mcusercontent.com/523af143bc18413a060734433/files/144a876f-ea05-472c-bd2f-79951ea80e94/IRIS_e_l_offerta_formativa_CB_MDB_BM.pdf"
                    target="_blank"
                  >
                    Consulta l'Offerta Formativa Integrata di IRIS!
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Supporto alla comunicazione interna
                  </Typography>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Il Servizio intende contribuire ad una migliore
                    strutturazione dei flussi di comunicazione tra il Ministero
                    della Salute e le Reti Tematiche e i Grant Offices
                    degli&nbsp;IRCCS.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/3370873e-4e54-42e8-b5b7-55c2b5fa79b3.jpg"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Tra i prodotti delle attività di supporto alla comunicazione
                    interna, anche il Magazine trimestrale{" "}
                    <span style={{ color: "#ff6600" }}>
                      <strong>IRIS Mag'</strong>
                    </span>
                    , strumento informativo su indirizzi di policy a livello
                    europeo in tema Salute, iniziative ed eventi nazionali ed
                    internazionali di rilevanza per la ricerca sanitaria*.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    <strong>
                      *
                      <em>
                        Si invitano gli IRCCS a segnalare contenuti rilevanti
                        per la pubblicazione in IRIS Mag':&nbsp;
                      </em>
                    </strong>
                    <em>
                      Il Magazine offre anche uno spazio per condividere le
                      novità che riguardano i progetti e le attività degli IRCCS
                      a livello nazionale ed europeo!
                    </em>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    Le novità da non perdere in tema di ricerca e innovazione in
                    Sanità saranno comunicate anche via email.{" "}
                    <strong>Per ricevere le</strong>{" "}
                    <span style={{ color: "#ff6600" }}>
                      <strong>IRIS News</strong>
                    </span>{" "}
                    è necessario richiedere di essere inseriti nella{" "}
                    <em>mailing list </em>dell'iniziativa IRIS, scrivendo a{" "}
                    <u>debonis@apre.it</u>&nbsp;e specificando il proprio ruolo
                    all'interno dell'IRCCS.
                  </Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/dd7b8004-8b44-4759-8d29-aae84791fd72.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={3.5}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/e343b23d-5533-43f5-b5ac-27ccb343fcbe.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    <span style={{ color: "#ff6600" }}>
                      <strong>3 Gruppi di Lavoro (GdL)&nbsp;IRIS</strong>
                    </span>
                    &nbsp;(Formazione; Informazione &amp; Assistenza; Networking
                    &amp; Brokerage) coinvolgeranno i Grant Office degli IRCCS e
                    i Ricercatori interessati in un ciclo di incontri basato
                    sulla&nbsp;riflessione sui risultati dell’analisi della
                    partecipazione e della performance degli IRCCS ai bandi
                    H2020, che contribuirà a migliorare in corso d'opera i
                    servizi a supporto degli Istituti.{" "}
                    <strong>Per richiedere di partecipare</strong>, è necessario
                    scrivere a <u>debonis@apre.it </u>specificando il proprio
                    ruolo all'interno dell'IRCCS e il gruppo in cui si intende
                    essere inserito.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box pb={1}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography variant="h6" gutterBottom>
                    Partecipazione ai processi di policy a livello UE
                  </Typography>
                  <Typography variant="body1" gutterBottom textAlign="justify">
                    I servizi di <em>Antenna Strategica</em> e{" "}
                    <em>Hub di informazione</em> presso le sedi di Bruxelles
                    monitoreranno le iniziative di policy a livello europeo in
                    tema Salute, con l'obiettivo di
                    <strong> posizionare il sistema-IRCCS </strong>rispetto agli
                    orientamenti di policy a livello dell'Unione, presentandone
                    le potenzialità e le sfide nel contesto
                    dell’internazionalizzazione della ricerca.
                  </Typography>
                  <Divider></Divider>
                  <Typography
                    variant="body1"
                    gutterBottom
                    textAlign="justify"
                    mt={1}
                  >
                    Le iniziative nell'ambito di questo servizio saranno
                    sviluppate in sinergia con le{" "}
                    <strong>Direzioni Scientifiche </strong>degli IRCCS.
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <img
                    alt=""
                    src="https://mcusercontent.com/523af143bc18413a060734433/images/97ef2010-0ad0-420c-a311-ba16cfa21037.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    bgcolor="#EA5B3A"
                    textAlign="center"
                    pl={12}
                    pr={12}
                    pt={3}
                    pb={3}
                  >
                    <img
                      alt=""
                      src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/0dfef934-74b3-43de-8199-dd97326fba50.png"
                      width="100%"
                    ></img>
                    <Typography variant="h5" gutterBottom pt={1} color="white">
                      <strong>CONTATTI</strong>
                    </Typography>
                    <Typography variant="body2" color="white">
                      <strong>Tel.</strong> +39 06 48 93 99 93 - Fax +39 06 48
                      90 25 50
                    </Typography>
                    <Typography variant="body2" color="white">
                      <strong>e-mail: </strong>iris@sanita.it; debonis@apre.it
                      (per info su IRIS)
                    </Typography>
                    <Typography variant="body2" color="white">
                      iris-helpdesk@apre.it (per assistenza su H2020)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <img
                    alt=""
                    src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/6248db66-869e-46eb-87c7-61eac936a3b3.png"
                    width="100%"
                  ></img>
                </Grid>
                <Grid item xs={12} textAlign="center" pt={1} pb={1}>
                  <strong>
                    Torna alla <NavLink to="/iris">HOME</NavLink>
                  </strong>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    bgcolor="#ebebeb"
                    textAlign="center"
                    pl={4}
                    pr={4}
                    pt={3}
                    pb={2}
                  >
                    <Typography variant="caption" color="#656565">
                      <em>
                        Copyright © IRIS 2019, Ministero della Salute. All
                        rights reserved.
                      </em>
                      <br></br>
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      <strong>
                        Iniziativa finanziata dal Ministero della Salute:
                      </strong>
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      Servizio di “Supporto agli Istituti di ricovero e cura a
                      carattere scientifico (IRCCS) per
                      <br></br>
                    </Typography>
                    <Typography variant="caption" color="#656565">
                      l’internazionalizzazione della ricerca – Anni 2020 e 2021”
                      (CIG 8220564005)
                      <br></br>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
