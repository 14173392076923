import React from "react";
import { Box, Container, Stack } from "@mui/material";
import "../IRIS.css";
import IRISintestazione from "./IRISIntestazione";
import IRISServiziOfferti from "./IRISServiziOfferti";
import IRISHighlights from "./IRISHiglights";
import IRISFactsSheets from "./IRISFatctsSheets";
import IRISBrokerage from "./IRISBrokerage";
import IRISAlertsNews from "./IRISAlertsNews";
import IRISTalk from "./IRISTalk";
import IRISWebinar from "./IRISWebinar";
import IRISHorizon from "./IRISHorizon";
import IRISTeam from "./IRISTeam";

export default function IRISHome() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2}>
            
            <IRISintestazione />

            <IRISServiziOfferti />

            <IRISHighlights />

            <IRISFactsSheets />

            <IRISBrokerage />

            <IRISAlertsNews />

            <IRISTalk />

            <IRISWebinar />

            <IRISHorizon />

            <IRISTeam />

          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
