import React from "react";
import {
  Box,
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import "./BussoleIRCSS.css";
import { NavLink } from "react-router-dom";
import CopertinaMaterialBiologico from "../../assets/copertina-materiale-biologico.png";
import CopertinaMedicinaGenere from "../../assets/copertina-medicina-genere.png";
import CopertinaStudiClinici from "../../assets/copertina-studi-clinici.png";
import CopertinaMedicinaGenere2021 from "../../assets/copertina-medicina-genere-2021.png";

export default function BussoleIRCCSArchivio() {
  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA">
        <Container maxWidth="sm">
          <Stack spacing={2} pb={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={2}
              pr={15}
              pl={15}
            >
              <img
                alt=""
                src="https://gallery.mailchimp.com/523af143bc18413a060734433/images/79405f16-1688-4d88-a469-692bc2a52fa8.jpg"
                width="100%"
              ></img>
            </Box>
            <Box bgcolor="#EA5B3A" textAlign="center">
              <Typography variant="h5" pt={2} pb={2} color="white">
                <strong>Bussole IRCCS - ARCHIVIO</strong>
              </Typography>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box textAlign="center">
              <Stack spacing={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Il materiale biologico IRCCS
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#656565"
                  textAlign="justify"
                >
                  "Bussole IRCCS - Il materiale biologico IRCCS"
                </Typography>
                <Box pl={17} pr={17}>
                  <img
                    alt=""
                    src={CopertinaMaterialBiologico}
                    width="100%"
                  ></img>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: "#2FC399",
                  }}
                  href="https://dgr.cbim.it/index.php/s/fPFEyhHHFQsusVs/download"
                  target="_blank"
                >
                  Download &quot;Il materiale biologico IRCCS&quot;
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box textAlign="center">
              <Stack spacing={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Medicina di genere e COVID-19
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#656565"
                  textAlign="justify"
                >
                  "Bussole IRCCS - Medicina di genere e COVID-19"
                </Typography>
                <Box pl={17} pr={17}>
                  <img alt="" src={CopertinaMedicinaGenere} width="100%"></img>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: "#2FC399",
                  }}
                  href="https://dgr.cbim.it/index.php/s/OkprgCt3uD8kfyQ/download"
                  target="_blank"
                >
                  Download "Medicina di genere e COVID-19"
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box textAlign="center">
              <Stack spacing={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Studi clinici IRCCS e prospettive: strumenti, modelli e
                  procedure
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#656565"
                  textAlign="justify"
                >
                  "Bussole IRCCS - Studi clinici IRCCS e prospettive: strumenti,
                  modelli e procedure"
                </Typography>
                <Box pl={17} pr={17}>
                  <img alt="" src={CopertinaStudiClinici} width="100%"></img>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: "#2FC399",
                  }}
                  href="https://dgr.cbim.it/index.php/s/eITMv0S2WO3QCbX/download"
                  target="_blank"
                >
                  Download &quot;Studi clinici IRCCS e prospettive: strumenti,
                  modelli e procedure&quot;
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Box textAlign="center">
              <Stack spacing={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Medicina di genere e COVID-19 (aggiornamento 2021)
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#656565"
                  textAlign="justify"
                >
                  "Bussole IRCCS - Medicina di genere e COVID-19 (aggiornamento
                  2021)"
                </Typography>
                <Box pl={17} pr={17}>
                  <img
                    alt=""
                    src={CopertinaMedicinaGenere2021}
                    width="100%"
                  ></img>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: "#2FC399",
                  }}
                  href="https://dgr.cbim.it/index.php/s/tWbHI1FXg7Ep9Ws/download"
                  target="_blank"
                >
                  Download &quot;Medicina di genere e COVID-19 (aggiornamento
                  2021)&quot;
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
            <Grid item xs={12} textAlign="center" pt={2} pb={2}>
              <strong>
                Torna alla <NavLink to="/bussole-ircss">HOME</NavLink>
              </strong>
            </Grid>
            <Divider sx={{ borderBottomWidth: 5 }}></Divider>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
