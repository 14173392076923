import React from "react";
import { Box, Container, Stack, Grid, Typography } from "@mui/material";
import photo_1 from "../../assets/dbmanager.png";
//import photo_2 from "../../assets/fdev.svg";
import photo_3 from "../../assets/logo_iris.png";
import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  const home_item = [
    {
      text1: "INFORMAZIONI",
      text2: "INFORMAZIONI_SUB",
      text3: "INFORMAZIONI_CORPO",
      text4: "INFORMAZIONI_SUBCORPO",
      img: photo_1,
    },
    /*{
      text1: "OPEN_DATA",
      text2: "OPEN_DATA_SUB",
      text3: "OPEN_DATA_CORPO",
      text4: "OPEN_DATA_SUBCORPO",
      img: photo_2,
    },*/
    {
      text1: "IRIS",
      text2: "IRIS_TITLE",
      text3: "IRIS_CORPO",
      text4: "IRIS_SUBCORPO",
      img: photo_3,
    },
  ];

  return (
    <Box pt={7} pb={7} pl={4} pr={4} bgcolor="#eeeeee">
      <Box bgcolor="white" border={2} borderColor="#FAFAFA" pb={2}>
        <Container>
          <Stack spacing={2}>
            {home_item.map((item, i) => {
              return (
                <Box pt={4} key={i}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography variant="h5" component="div" color="#06c">
                        <b>{t(item.text1)}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box typography="body1" color="#000000DE">
                        <p>
                          <b>{t(item.text2)}</b>
                          <br />
                          {t(item.text3)}
                        </p>
                      </Box>
                      <Box typography="body2">
                        <p>{t(item.text4)}</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Box>
                        <img src={item.img} className={styles.img1} alt={"Home img n." + i}></img>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
