import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
  Box,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import {
  GET_DI,
  GET_IRG,
  GET_PROGETTI_RF,
  GET_PROGETTO_RF_BY_CODICE,
} from "../../GraphQL/Queries";
import MouseOverPopover from "../MouseOverPopover/MouseOverPopover";
import DataTable from "../DataTable/DataTable";

let inputForm = {};

export default function FormRf() {
  const { t } = useTranslation();

  const min_year = 1910;
  const max_year = new Date().getFullYear();

  const initialState = {
    anno: new Date().getFullYear(),
    codiceProgetto: "",
    idIstituto: "",
    // nomeResponsabile: "",
    // cognomeResponsabile: "",
    titolo: "",
    paroleChiave: "",
    irgId: "",
  };

  const [formValue, setformValues] = React.useState(initialState);

  const [submit, setSubmit] = React.useState(false);

  const handleChange = (evnt) => {
    const inputFieldValue = evnt.target.value;
    const inputFieldName = evnt.target.name;
    const NewInputValue = { ...formValue, [inputFieldName]: inputFieldValue };
    setformValues(NewInputValue);
    setSubmit(false);
  };

  const handleReset = () => {
    setformValues(initialState);
  };

  const handleSubmit = (event) => {
    inputForm = {
      ...formValue,
      anno: formValue.anno !== "" ? parseInt(formValue.anno, 10) : null,
      idIstituto:
        formValue.idIstituto !== "" ? parseInt(formValue.idIstituto, 10) : null,
      irgId: formValue.irgId !== "" ? parseInt(formValue.irgId, 10) : null,
    };
    setSubmit(true);
    event.preventDefault();
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
  });

  // controllo parametri form, se esiste almeno un campo non vuoto OK , se tutti i campi vuoti ERRORE
  const checkParameters = (formValue) => {
    let show = true;
    for (const value of Object.values(formValue)) {
      if (value.length !== 0) {
        show = false;
      }
    }
    return show;
  };

  const DI = useQuery(GET_DI);
  const IRG = useQuery(GET_IRG);

  if (DI.loading || IRG.loading)
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  if (DI.error)
    return (
      <Alert severity="error">
        <AlertTitle>Error!</AlertTitle>
        {DI.error.message}
      </Alert>
    );
  if (IRG.error)
    return (
      <Alert severity="error">
        <AlertTitle>Error!</AlertTitle>
        {IRG.error.message}
      </Alert>
    );

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-standard-label">
                {t("DESTINATARIO_ISTITUZIONALE")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={t("DESTINATARIO_ISTITUZIONALE")}
                value={formValue.idIstituto}
                name="idIstituto"
                onChange={handleChange}
              >
                {DI.data.getDI.map((DI) => (
                  <MenuItem key={DI.id} value={DI.id}>
                    {DI.nomeBreve}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("DESTINATARIO_ISTITUZIONALE")}
              description={t("DESTINATARIO_ISTITUZIONALE_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label={t("ANNO_FINANZIAMENTO")}
              variant="standard"
              value={formValue.anno}
              inputProps={{ min: min_year, max: max_year }}
              type="number"
              fullWidth
              onChange={handleChange}
              name="anno"
            />
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("ANNO_FINANZIAMENTO")}
              description={t("ANNO_FINANZIAMENTO_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label={t("TITOLO")}
              variant="standard"
              fullWidth
              value={formValue.titolo}
              onChange={handleChange}
              name="titolo"
            />
          </Grid>
          {/* <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("NOME_RESPONSABILE")}
              variant="standard"
              fullWidth
              value={formValue.nomeResponsabile}
              onChange={handleChange}
              name="nomeResponsabile"
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("COGNOME_RESPONSABILE")}
              variant="standard"
              value={formValue.cognomeResponsabile}
              fullWidth
              onChange={handleChange}
              name="cognomeResponsabile"
            />
          </Grid> */}
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("CODICE_PROGETTO")}
              variant="standard"
              fullWidth
              value={formValue.codiceProgetto}
              name="codiceProgetto"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("CODICE_PROGETTO")}
              description={t("CODICE_PROGETTO_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={1.8}>
            <TextField
              id="standard-basic"
              label={t("PAROLE_CHIAVE")}
              variant="standard"
              fullWidth
              value={formValue.paroleChiave}
              name="paroleChiave"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title={t("PAROLE_CHIAVE")}
              description={t("PAROLE_CHIAVE_DESC")}
            ></MouseOverPopover>
          </Grid>
          <Grid item xs={1.8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-standard-label">IRG:</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="IRG:"
                name="irgId"
                value={formValue.irgId}
                onChange={handleChange}
              >
                {IRG.data.getNihIrg.map((IRG) => (
                  <MenuItem key={IRG.id} value={IRG.id}>
                    {IRG.descrizione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} mt={1.5}>
            <MouseOverPopover
              title="IRG"
              description={t("IRG_DESC")}
            ></MouseOverPopover>
          </Grid>
          {checkParameters(formValue) && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>{t("API_KEY_ALERT")}</AlertTitle>
                {t("PARAMETRI_RICERCA")}
              </Alert>
            </Grid>
          )}

          <Grid item xs={9}></Grid>
          <Grid item xs={1.5}>
            <Button
              type="submit"
              variant="contained"
              p={2}
              disabled={checkParameters(formValue)}
            >
              {t("CERCA")}
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="neutral"
                p={2}
                onClick={handleReset}
              >
                {t("PULISCI")}
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </form>
      <Box mt={3}>
        {submit && (
          //pass form values, graphql query, and the name of the field returned by the backend used as ID
          <DataTable
            formValue={inputForm}
            query={GET_PROGETTI_RF}
            detailQuery={GET_PROGETTO_RF_BY_CODICE}
            uniqueField="codiceProgetto"
            detailButtonFlag={false}
          ></DataTable>
        )}
      </Box>
    </Box>
  );
}
