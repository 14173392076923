import React from "react";
import { Box, Typography , Button } from "@mui/material";
import "../IRIS.css";
import HighlightsIMG from '../../../assets/highlights.png';
import PrimoBandoHeIMG from '../../../assets/primo_bando_he_cl1_2021.png';

export default function IRISHighlights() {

    function openLinkReport(){
        const urlReport = "https://mcusercontent.com/523af143bc18413a060734433/files/fb5bcaf1-7aa0-fb9b-01d0-1ec400deb8c1/R1.1.3_quantitativa_HE_2021.pdf"
        window.open(urlReport, '_blank', 'noopener,noreferrer');
    }

  return (
    <div>
        
        <Box bgcolor="#EA5B3A" textAlign="center">
            <Typography variant="body1" pt={2} pb={2} color="white">
                HIGHLIGHTS
            </Typography>
        </Box>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={HighlightsIMG}
                width="100%"
            />
        </Box>
        
        <Box>
            <Typography variant="body2" textAlign="justify">
                <br/>
                <strong>
                    IRCCS IN HORIZON EUROPE
                </strong><br/>
                Analisi quantitativa della partecipazione al bando 2021 – Cluster «Health»
            </Typography>
        </Box>

        <br/>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={PrimoBandoHeIMG}
                width="100%"
            />
        </Box>

        <br/>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                Questo <b className="textHighlight" >Report</b> intende offrire <b>una panoramica della partecipazione e della 
                performance degli Istituti di Ricovero e Cura a Carattere Scientifico (IRCCS) nell’ambito Cluster «Health»</b> 
                (CL1) del Programma Quadro di Ricerca & Innovazione HORIZON EUROPE, con particolare riferimento ai bandi del 2021 
                (24 topics), e in una prospettiva comparativa rispetto alla partecipazione nel precedente Programma Quadro (HORIZON 2020) 
                e alla performance registrata complessivamente a livello europeo e nazionale. 
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                I dati sono stati analizzati prendendo in considerazione il posizionamento nella ranking list europea e il ruolo degli IRCCS 
                nelle proposte presentate (Coordinatori o Partner), valutando il tasso di successo degli stessi, sia in termini di rapporto 
                tra il n. di partecipazioni finanziate e il n. di partecipazioni in proposte presentate (<b>tasso di successo #</b>), 
                sia in termini di rientro economico (<b>tasso di successo €</b>), ossia di finanziamento effettivamente allocato rispetto 
                al budget complessivamente richiesto in fase di proposta dagli IRCCS partecipanti. 
                La partecipazione e la performance degli IRCCS sono state analizzate anche considerando gli ambiti di ricerca 
                (<b><i>Destination</i></b>) dei bandi e la tipologia di enti finanziati. Sono state raccolte, infine, le informazioni di dettaglio 
                relative alle sole proposte finanziate coordinate/partecipate da IRCCS. 
            </Typography>
        </Box>

        <hr/>

        <Box>
            <Typography style={{fontSize:"small"}}  textAlign="justify" className="textGrey" >
                <i>  
                    [!] Il report include un ANNEX sugli esiti della partecipazione degli IRCCS ai bandi della <b>Mission «Cancer»</b> di Horizon Europe.<br/>
                    [!] Il servizio di analisi dei dati IRIS ha previsto anche un'analisi qualitativa della partecipazione in Horizon Europe, 
                    i cui risultati sono stati messi a disposizione della Direzione Generale della Ricerca ed Innovazione in Sanità del 
                    Ministero della Salute. 
                </i>
            </Typography>
        </Box>

        <br/>
        <Box bgcolor="#4CAAD8" className="buttonLinkContainer" textAlign="center" role="button" onClick={()=>openLinkReport()} >
            <Button style={{textTransform:"none"}}>
                <Typography variant="h6" pt={0} pb={0} color="white">
                    Scarica il Report
                </Typography>
            </Button>
        </Box>
        <br/>
        <hr/>
    </div>
  );
}
