import React from "react";
import { Box, Typography } from "@mui/material";
import "../IRIS.css";
import IRISHorizonIMG from '../../../assets/horizone_europe.jpg';

export default function IRISHorizon() {
  return (
    <div>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={2}
        >
            <img
                alt=""
                src={IRISHorizonIMG}
                width="100%"
            />
            
        </Box>

        <Box style={{backgroundColor:"#8B8484",color:"#F2F2F2",padding:"1em"}}>
            <Typography variant="body2" gutterBottom textAlign="justify" style={{fontSize:"small"}} >
                IRIS ha offerto un servizio di Help Desk dedicato agli IRCCS e alle loro esigenze di informazione 
                e assistenza riguardanti la partecipazione ai bandi europei.<br/>
                Il servizio è stato erogato da personale qualificato e di eccellente esperienza, tra cui i National Contact Point di Horizon Europe. 
                In APRE i <a href="https://horizoneurope.apre.it/team-tematici/" target="_blank"  rel="noopener noreferrer" >Team tematici</a> 
                offrono informazioni e assistenza a chiunque abbia interesse a partecipare a Horizon Europe, incluse Missions e Partnership.
                <hr/>
                <p style={{textAlign:"center"}}>
                    Per informazioni e per rimanere in contatto con APRE scrivi a: <br/>
                    <a href="mailto:cluster1@apre.it" target="_blank" rel="noopener noreferrer"><b style={{color:"#F2F2F2"}}>cluster1@apre.it</b></a> 
                </p>
            </Typography>
        </Box>

        <br/>
    </div>
  );
}
